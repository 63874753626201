import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Button, TextField, Tooltip, IconButton, Box, Tabs, Tab, Typography} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { createReturnsAnalysisColumns_Weekly, createReturnsAnalysisColumns_Monthly } from './returns_analysis_pivot.js';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import { styled } from '@mui/material/styles';

const ReturnsAnalysis = () => {
  const [rowDataWeekly, setRowDataWeekly] = useState([]);
  const [columnDefsWeekly, setColumnDefsWeekly] = useState([]);
  
  const [rowDataMonthly, setRowDataMonthly] = useState([]);
  const [columnDefsMonthly, setColumnDefsMonthly] = useState([]);
  
  const [error, setError] = useState(null);
  const weeklyGridRef = useRef(null);
  const monthlyGridRef = useRef(null);
  const [quickFilterText, setQuickFilterText] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const handleWeeklyData = () => {
    document.title = "Returns Analysis Weekly | Encasa HQ";
    console.log('Fetching Weekly Returns Analysis data');
    
    fetch('/api/orders/get_returned_data_weekly')
      .then(response => {
        console.log('Received response from API');
        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Data successfully parsed as JSON');
        if (Array.isArray(data)) {
          // console.log('Raw data sample:', data.slice(0, 2)); 
          console.log(`Number of weekly rows received: ${data.length}`);
          
          const transformData_weekly = (data) => {
            const rowMap = new Map();
          
            data.forEach(item => {
              try {
                if (!item || !item.country_code || !item.line) {
                  console.warn('Invalid item data:', item);
                  return;
                }
          
                const key = `${item.country_code}_${item.line}`;
                
                if (!rowMap.has(key)) {
                  rowMap.set(key, {
                    country_code: item.country_code,
                    line: item.line
                  });
                }
          
                const row = rowMap.get(key);
                const yearWeek = item.week;
          
                // Skip if yearWeek is null/undefined
                if (!yearWeek) {
                  console.warn('Missing week data for item:', item);
                  return;
                }
          
                row[`quantity_ordered_${yearWeek}`] = item.quantity_ordered;
                row[`quantity_returned_${yearWeek}`] = item.quantity_returned;
                row[`returned_ratio_${yearWeek}`] = item.returned_ratio;
              } catch (error) {
                console.error('Error processing data item:', item, error);
              }
            });
          
            return Array.from(rowMap.values());
            };
          
          // Transform the data
          const transformedData = transformData_weekly(data);
          // console.log('Transformed data sample:', transformedData.slice(0, 2)); 
          setRowDataWeekly(transformedData);
          
          // Create columns
          const columns = createReturnsAnalysisColumns_Weekly(data);
          // console.log('Column definitions sample:', columns.slice(0, 3)); 
          setColumnDefsWeekly(columns);
          
          // Verify data structure
          const sampleRow = transformedData[0];
          console.log('Sample row keys:', Object.keys(sampleRow));
          console.log('Sample year-week combinations:', data.map(item => item.week).slice(0, 3)); // Log first 3 year-week combinations
        } else {
          throw new Error('Received weekly data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Weekly Returns Analysis data:', error);
        setError(error.message);
        setRowDataWeekly([]);
      });
  };

  const handleMonthlyData = () => {
    document.title = "Returns Analysis Monthly | Encasa HQ";
    console.log('Fetching Monthly Returns Analysis data');
    
    fetch('/api/orders/get_returned_data_monthly')
      .then(response => {
        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of monthly rows received: ${data.length}`);
          
          const transformedData = transformData_monthly(data);
          setRowDataMonthly(transformedData);
          
          const columns = createReturnsAnalysisColumns_Monthly(data);
          setColumnDefsMonthly(columns);
        } else {
          throw new Error('Received monthly data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Monthly Returns Analysis data:', error);
        setError(error.message);
        setRowDataMonthly([]);
      });
  };
  
  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
        if (isMounted) {
          await handleWeeklyData();
          await handleMonthlyData();
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
          setError(error.message);
        }
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
      setError(null);
      setRowDataWeekly([]);
      setRowDataMonthly([]);
    };
  }, []);

  const transformData_weekly = (data) => {
    const transformedDataWeekly = {};
  
    data.forEach(item => {
      const key = `${item.country_code}_${item.line}`;
      if (!transformedDataWeekly[key]) {
        transformedDataWeekly[key] = {
          country_code: item.country_code,
          line: item.line,
        };
      }
      transformedDataWeekly[key][`quantity_ordered_${item.week}`] = item.quantity_ordered;
      transformedDataWeekly[key][`quantity_returned_${item.week}`] = item.quantity_returned;
      transformedDataWeekly[key][`returned_ratio_${item.week}`] = item.returned_ratio;      
    });
  
    return Object.values(transformedDataWeekly);
  };

  const transformData_monthly = (data) => {
    const transformedData = {};
    data.forEach(item => {
      const key = `${item.country_code}_${item.line}`;
      if (!transformedData[key]) {
        transformedData[key] = {
          country_code: item.country_code,
          line: item.line,
        };
      }
      transformedData[key][`quantity_ordered_${item.month}`] = item.quantity_ordered;
      transformedData[key][`quantity_returned_${item.month}`] = item.quantity_returned;
      transformedData[key][`returned_ratio_${item.month}`] = item.returned_ratio;      
    });
  
    return Object.values(transformedData);
  };

  const handleExportCSV = () => {
    console.log('Exporting CSV...');
    if (activeTab === 0) {
      weeklyGridRef.current.api.exportDataAsCsv();
    } else {
      monthlyGridRef.current.api.exportDataAsCsv();
    }
  };

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'columns', labelDefault: 'Columns',  labelKey: 'columns', 
        iconKey: 'columns',  toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',  labelDefault: 'Filters',  labelKey: 'filters',  
        iconKey: 'filter',  toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: null,
  }), []);

  const handleClearFilters = useCallback(() => {
    if (activeTab === 0 && weeklyGridRef.current) {
      weeklyGridRef.current.api.setFilterModel(null);
    } else if (activeTab === 1 && monthlyGridRef.current) {
      monthlyGridRef.current.api.setFilterModel(null);
    }
    setQuickFilterText('');
  }, [activeTab]);

  const handleQuickFilterChange = useCallback((filterText) => {
    setQuickFilterText(filterText);
  }, []);

  const handleReturnAnalysisClearCache = useCallback(() => {
    fetch('/api/orders/clear_return_analysis_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Cache cleared:', data);
        handleWeeklyData();
        handleMonthlyData();
      })
      .catch(error => console.error('Error clearing cache:', error));
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      minWidth: '150px',
    },
  }));
  
  const StyledTab = styled(Tab)(({ theme }) => ({
    marginRight: theme.spacing(2),
    '&.Mui-selected': {
      fontWeight: 'bold',  
    }
  }));

  return (
    <Box m="5px">
      <Typography variant="h5" component="h1" gutterBottom style={{ fontWeight: 'bold', marginBottom: '2.5px' }}>
      </Typography>
      <StyledTabs 
        value={activeTab} 
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
      >
        <StyledTab label="Weekly Returns" />
        <StyledTab label="Monthly Returns"/>
      </StyledTabs>
   
      {activeTab === 0 && (
        <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={-3}>
            <Typography variant="h5">Weekly Returns Analysis</Typography>
            <Box display="flex" alignItems="center">
              <QuickFilter onFilterChange={handleQuickFilterChange} />
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleClearFilters} 
                style={{ marginRight: '10px' }}
              >
                Clear Filters
              </Button>
              <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }}>
                Export CSV
              </Button>
              <Button 
                variant="contained" 
                color="warning" 
                onClick={handleReturnAnalysisClearCache} 
                style={{ marginRight: '10px' }}
              >
                Clear Cache
              </Button>
            </Box>
          </Box>
          {error ? (
            <Typography color="error" variant="h6">
              Error: {error}
            </Typography>
          ) : (
            <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
              <AgGridReact
                ref={weeklyGridRef}
                rowData={rowDataWeekly}
                columnDefs={columnDefsWeekly}
                defaultColDef={{
                  sortable: true,  filter: true,  resizable: true,
                  wrapHeaderText: true,  autoHeaderHeight: true,
                }}
                enableRangeSelection={true}  multiSortKey="ctrl"  sideBar={sideBar}  pagination={true}
                paginationPageSize={50}  paginationPageSizeSelector={[50, 100, 500, 1000]}
                paginationNumberFormatter={(params) => {
                  return '[' + params.value.toLocaleString() + ']';
                }}
                rowHeight={25}
                quickFilterText={quickFilterText}
              />
            </div>
          )}
        </div>
      )}
      {activeTab === 1 && (
        <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={-3}>
            <Typography variant="h5">Monthly Returns Analysis</Typography>
            <Box display="flex" alignItems="center">
              <QuickFilter onFilterChange={handleQuickFilterChange} />
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleClearFilters} 
                style={{ marginRight: '10px' }}
              >
                Clear Filters
              </Button>
              <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }}>
                Export CSV
              </Button>
              <Button 
                variant="contained" 
                color="warning" 
                onClick={handleReturnAnalysisClearCache} 
                style={{ marginRight: '10px' }}
              >
                Clear Cache
              </Button>
            </Box>
          </Box>
          {error ? (
            <Typography color="error" variant="h6">
              Error: {error}
            </Typography>
          ) : (
            <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
              <AgGridReact
                ref={monthlyGridRef}
                rowData={rowDataMonthly}
                columnDefs={columnDefsMonthly}
                defaultColDef={{
                  sortable: true,  filter: true,  resizable: true,
                  wrapHeaderText: true,  autoHeaderHeight: true,
                }}
                enableRangeSelection={true}  multiSortKey="ctrl"  sideBar={sideBar}  pagination={true}
                paginationPageSize={50}  paginationPageSizeSelector={[50, 100, 500, 1000]}
                paginationNumberFormatter={(params) => {
                  return '[' + params.value.toLocaleString() + ']';
                }}
                rowHeight={25}
                quickFilterText={quickFilterText}
              />
            </div>
          )}
        </div>
      )}    
    </Box>             
  );
};

export default ReturnsAnalysis;
