import AlertPillsCellRenderer from '../../components/global/AlertPillsCellRenderer.jsx';
import { createElement } from 'react';
import ScratchpadNotes from '../../components/global/ScratchpadNotes';

const createReturnRatioColumn = (headerName, field, columnGroupShow = undefined) => ({
  headerName, field, sortable: true,
  filter: 'agNumberColumnFilter',
  width: 100, cellStyle: { textAlign: 'right' },
  valueFormatter: (params) => {
    if (params.value != null && params.value !== '' && params.value !== 0) {
      return Math.round(params.value * 100) + '%';
    }
    return '';
  },
  columnGroupShow
});

export const cospColumns = [
    {
      headerName: 'Product Information',
      children: [
        { headerName: "Ctry", field: "country_code", sortable: true, filter: true, width: 55, pinned: 'left', enableRowGroup: true },
        { 
          headerName: "SKU", 
          field: "sku", 
          sortable: true, 
          filter: 'agTextColumnFilter', 
          width: 135, 
          pinned: 'left', 
          enableRowGroup: true,
          filterParams: {
            filterOptions: [
              { 
                displayKey: 'multipleSKUs',
                displayName: 'Multiple SKUs',
                predicate: ([filterValue], cellValue) => {
                  if (!filterValue) return true;
                  const skus = filterValue.replace(/\s*,\s*/g, ' ')  // Replace commas with spaces
                                          .split(/\s+/)              // Split on one or more spaces
                                          .filter(sku => sku !== '');
                  return skus.includes(cellValue);
                },
                numberOfInputs: 1,
              }
            ],
          }
        },
        { headerName: "Line", field: "Line", sortable: true, filter: true, width: 80, pinned: 'left', enableRowGroup: true },
        { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 60, pinned: 'left', enableRowGroup: true },
        { 
          headerName: "",
          field: "sales_channel", 
          width: 60,
          pinned: 'left',
          cellRenderer: params => {
            if (!params.data || !params.data.asin) {
              return null;
            }
            const url = `https://www.${params.value}/dp/${params.data.asin}`;
            return createElement('a', {
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
              style: { textDecoration: 'none' }
            }, '🔗');
          }
        },
        {
          headerName: "",
          field: "notes",
          width: 45,
          sortable: false,
          filter: false,
          pinned: 'left',
          cellRenderer: params => {
            if (!params.data) return null;
            
            return (
              <ScratchpadNotes
                line={params.data.Line}
                country={params.data.country_code}
                sku={params.data.sku}
              />
            );
          }
        },
        {  headerName: "ASIN",  field: "asin",  sortable: true,  filter: 'agTextColumnFilter',  width: 135, 
          // columnGroupShow: 'open',
          filterParams: {
            filterOptions: [
              { displayKey: 'multipleAsins',
                displayName: 'Multiple ASINs',
                predicate: ([filterValue], cellValue) => {
                  if (!filterValue) return true;
                  const asins = filterValue.replace(/\s*,\s*/g, ' ')  // Replace commas with spaces
                                           .split(/\s+/)              // Split on one or more spaces
                                           .filter(asin => asin !== '');
                  return asins.includes(cellValue);
                },
                numberOfInputs: 1,
              }
            ],
          }
        },
        { headerName: "Region", field: "channel_abb", sortable: true, filter: true, width: 125, columnGroupShow: 'open' },
        { headerName: "Channel", field: "sales_channel", sortable: true, filter: true, width: 125, columnGroupShow: 'open' },
        { headerName: "Cat", field: "cat", sortable: true, filter: true, width: 65, cellStyle: { textAlign: 'center' } },
        { headerName: "Size", field: "size", sortable: true, filter: true, width: 135, enableRowGroup: true },
        { headerName: "Color", field: "color", sortable: true, filter: true, width: 135, enableRowGroup: true },
        { headerName: "Pcs Pack", field: "pcs_pack", sortable: true, filter: true, width: 120, cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
        { headerName: "Prodn", field: "production", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Group", field: "group", sortable: true, filter: true, width: 110, columnGroupShow: 'open', enableRowGroup: true },
        { headerName: "Segment", field: "segment", sortable: true, filter: true, width: 110, columnGroupShow: 'open', enableRowGroup: true },
        { headerName: "Fabric", field: "fabric", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Short Desc", field: "short_desc", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Launch Date", field: "launch_date", sortable: true, filter: 'agDateColumnFilter', width: 110, columnGroupShow: 'open' },
        { 
          headerName: "Status", field: "status", sortable: true, filter: true, width: 110, enableRowGroup: true,
          cellStyle: params => {
            if (params.value === 'grouped_child') {
              return { color: 'green' };
            } else if (params.value === 'ungrouped_child') {

              return { color: 'red' };
            }
            return null;
          }
        },
        { headerName: "Price", field: "your_price", sortable: true, filter: 'agNumberColumnFilter' , width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toFixed(2)}` : '', columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'Alerts',
      children: [
        {
          headerName: "Alerts",
          field: "alerts",
          sortable: true,
          filter: 'agSetColumnFilter',
          width: 100,
          cellRenderer: AlertPillsCellRenderer,
          valueGetter: params => params.data ? params.data.alerts : null,
          filterParams: {
            values: ['High DOC', 'Low Stock', 'Low CVR', 'LTSF', 'High ACOS', 'High Returns', 'No Sales 15d', null],
            comparator: (a, b) => {
              const order = ['High DOC', 'Low Stock', 'Low CVR', 'LTSF', 'High ACOS', 'High Returns', 'No Sales 15d', null];
              return order.indexOf(a) - order.indexOf(b);
            }
          },
          filterValueGetter: params => {
            if (!params.data || !params.data.alerts || 
                (Array.isArray(params.data.alerts) && params.data.alerts.length === 0)) {
              return null;
            }
            if (Array.isArray(params.data.alerts)) {
              return params.data.alerts.map(alert => 
                typeof alert === 'object' ? alert.message : alert
              ).join(', ');
            }
            return typeof params.data.alerts === 'object' ? params.data.alerts.message : params.data.alerts;
          },
          getQuickFilterText: params => {
            if (params.data && params.data.alerts) {
              if (Array.isArray(params.data.alerts)) {
                return params.data.alerts.map(alert => 
                  typeof alert === 'object' ? alert.message : alert
                ).join(' ');
              }
              return typeof params.data.alerts === 'object' ? params.data.alerts.message : params.data.alerts;
            }
            return '';
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const alertsA = nodeA && nodeA.data && nodeA.data.alerts ? 
                            (Array.isArray(nodeA.data.alerts) ? nodeA.data.alerts : [nodeA.data.alerts]) : [];
            const alertsB = nodeB && nodeB.data && nodeB.data.alerts ? 
                            (Array.isArray(nodeB.data.alerts) ? nodeB.data.alerts : [nodeB.data.alerts]) : [];
            return alertsA.length - alertsB.length;
          },
          headerTooltip: `Low Stock: FBA stock is low. DOC < 15 days. 
High DOC: DOC > 120 days. 
Low CVR: CVR < 5% as of Business Reports. 
LTSF: Product has Short or Long LTSF inventory. 
High ACOS: ACOS > 75% with more than 50 clicks. 
High Returns: Return rate > 20% for products with Units Sold > 5 in the last month.`,
        }
      ]
    },
    {
      headerName: 'Sales Data',
      children: [
        { headerName: "P7 Sales", field: "P7_sales", sortable: true, filter: 'agNumberColumnFilter',  width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "P7 MA", field: "P7_ma", sortable: true, filter: 'agNumberColumnFilter',   width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "L7 Sales", field: "L7_sales", sortable: true, filter: 'agNumberColumnFilter',  width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "L7 MA", field: "l7_ma", sortable: true, filter: 'agNumberColumnFilter',   width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "L30 MA", field: "l30_ma", sortable: true, filter: 'agNumberColumnFilter',   width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "L90 MA", field: "l90_ma", sortable: true, filter: 'agNumberColumnFilter',   width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "L365 MA", field: "l365_ma", sortable: true, filter: 'agNumberColumnFilter',  width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Region MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter',  width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum' },
        { headerName: "Max L30 MA", field: "maxL30_ma", sortable: 'agNumberColumnFilter',  filter: true, width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', columnGroupShow: 'open', aggFunc: 'sum' },
      ]
    },
    {
      headerName: 'Inventory',
      children: [
        { headerName: "Total Stock", field: "total_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "MFN Stock", field: "mfn_stock", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "FC Stock", field: "fc_stock", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
        { headerName: "Inbound Qty", field: "inbound_qty", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Inbound Date", field: "inbound_date", sortable: true, filter: 'agNumberColumnFilter', width: 120, columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Missed Days", field: "MissedDays", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open' },
        { headerName: "Last Sold Days", field: "last_sold_days", sortable: true, filter: 'agNumberColumnFilter', width: 120, columnGroupShow: 'open', cellStyle: { textAlign: 'right' } },
      ]
    },
    {
      headerName: 'Aging',
      children: [
        { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 110, 
          cellStyle: params => {
            if (params.value === 'high_doc' || params.value === 'at_risk') {
              return { color: '#FFA500', textAlign: 'left' };
            } else if (params.value === 'short_ltsf' || params.value === 'long_ltsf') {
              return { color: '#FF0000', textAlign: 'left' };
            }
            return { textAlign: 'left' };
          }
        },
        { headerName: "MA Flag", field: "ma_flag", sortable: true, filter: true, width: 100, cellStyle: { textAlign: 'left' }, columnGroupShow: 'open' },
        { headerName: "Inv at Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter:'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'PDP',
      children: [ 
        { headerName: "Last Rating Update", field: "lastRatingUpdate", sortable: true,filter: 'agDateColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open' },
        { headerName: "Ratings", field: "ratings", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum' },
        { headerName: "# Ratings", field: "rating_count", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Sales Rank", field: "currentsalesRank", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', aggFunc: 'sum', columnGroupShow: 'open' },        
        { headerName: "# Images SP-API", field: "number_of_images_spapi", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open' },
        { headerName: "# Images Keepa", field: "number_of_images", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'center' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
        { headerName: "Brand (Keepa)", field: "keepa_brand", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Brand (SP-API)", field: "sp_api_brand", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "# Bulletpoints", field: "bulletpoint_count", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'center' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open' },
        { headerName: "Has Description", field: "has_description", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open' },

        { headerName: "Fetched Date", field: "rp_fetched_date", sortable: true,filter: 'agDateColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open' },
        { headerName: "Coupon", field: "coupon_text", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Deal Batch", field: "deal_badge", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Has A+", field: "has_aplus", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Has Brand Story", field: "has_brandstory", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Has Video", field: "has_video", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Amazon's Choice", field: "is_amazon_choice", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Bestseller", field: "is_best_seller", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
        { headerName: "Prime Delivery", field: "is_prime", sortable: true, filter: true, width: 110, columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'SP Ads (Last 15 Days)',
      children: [
        { headerName: "Impressions", field: "impressions", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Clicks", field: "clicks", sortable: true, filter: 'agNumberColumnFilter',width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Clicks %", field: "clicks_perc", sortable: true, filter: 'agNumberColumnFilter',width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => {
          if (params.value != null && !isNaN(params.value)) { return `${params.value.toLocaleString('en-US', {maximumFractionDigits: 1})} %`; } return ''; }, aggFunc: 'sum' },
        { headerName: "Spend", field: "cost", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Sales 7d", field: "sales7d", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Orders 7d", field: "orders7d", sortable: true, filter: 'agNumberColumnFilter',width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "CTR", field: "ctr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, 
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const impressions = params.data.impressions; const clicks = params.data.clicks; return (impressions && clicks) ? (clicks/impressions) * 100: null; }, columnGroupShow: 'open' },
        { headerName: "CVR", field: "ads_cvr", sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const orders = params.data.orders7d; const clicks = params.data.clicks; return (orders && clicks) ? (orders/clicks) * 100: null; } , columnGroupShow: 'open'},
        { headerName: "ACoS", field: "acos", sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const cost = params.data.cost; const sales = params.data.sales7d; return (cost && sales) ? (cost / sales) * 100 : null; } },
        { headerName: "RoAS", field: "roas", sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)}`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const cost = params.data.cost; const sales = params.data.sales7d; return (cost && sales) ? (sales/cost): null; }, columnGroupShow: 'open' },
        { headerName: "ADR", field: "adr", sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const adv_sales = params.data.sales7d; const t_sales = params.data.sales; return (adv_sales && t_sales) ? (adv_sales/t_sales) * 100: null; }},
      ]
    },
    {
      headerName: 'Business Reports (ETL)',
      children: [
        { headerName: "L7 Page View", field: "w2_page_view", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',  aggFunc: 'sum' },
        { headerName: "P7 Page View", field: "w3_page_view", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',  columnGroupShow: 'open', aggFunc: 'sum' },
        ...Array.from({ length: 6 }, (_, i) => {
          const date = new Date();
          date.setFullYear(date.getFullYear(), date.getMonth() - (i + 1), 1); 
          const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' }) + " (P)";
          return { 
            headerName: formattedDate, 
            field: `m${i + 2}_page_view`,
            sortable: true, 
            filter: 'agNumberColumnFilter',
            width: 100,
            cellStyle: { textAlign: 'right' },
            columnGroupShow: i < 1 ? undefined : 'open',
            aggFunc: 'sum',
            valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
          };
        }),

        { headerName: "L7 Session", field: "w2_sessions", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "P7 Session", field: "w3_sessions", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open',aggFunc: 'sum' },
        ...Array.from({ length: 6 }, (_, i) => {
          const date = new Date();
          date.setFullYear(date.getFullYear(), date.getMonth() - (i + 1), 1); 
          const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' })+ " (S)";
          return { 
            headerName: formattedDate, 
            field: `m${i + 2}_page_view`,
            sortable: true, 
            filter: 'agNumberColumnFilter',
            width: 100,
            cellStyle: { textAlign: 'right' },
            columnGroupShow: i < 1 ? undefined : 'open',
            aggFunc: 'sum',
            valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
          };
        }),

        { headerName: "L7 CVR", field: "w2_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "P7 CVR", field: "w3_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        ...Array.from({ length: 6 }, (_, i) => {
          const date = new Date();
          date.setFullYear(date.getFullYear(), date.getMonth() - (i + 1), 1); 
          const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' })+ " (CVR)";
          return { 
            headerName: formattedDate, 
            field: `m${i + 2}_cvr`,
            sortable: true, 
            filter: 'agNumberColumnFilter',
            width: 100,
            cellStyle: { textAlign: 'right' },
            columnGroupShow: i < 1 ? undefined : 'open',
            aggFunc: 'sum',
            valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
          };
        }),
      ]
    },
    {
      headerName: 'Business Reports (Last 15 Days)',
      children: [
        // { headerName: "Parent ASIN", field: "parent_asin", sortable: true, filter: true, width: 135, columnGroupShow: 'open' },
        { headerName: "Page Views", field: "page_views", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Sessions", field: "sessions", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Orders", field: "orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Units", field: "units", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Sales", field: "sales", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',  aggFunc: 'sum' },
        // { headerName: "CVR", field: "cvr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value && typeof params.value === 'object' ? `${Math.round(params.value.ratio * 100)}%` :  (params.value ? `${Math.round(params.value * 100)}%` : ''), ...getRatioValue('orders', 'sessions') },
        // { headerName: "CVR", field: "cvr", sortable: true,filter: 'agNumberColumnFilter',width: 100,cellStyle: { textAlign: 'right' },
        // valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        // valueGetter: params => { if (!params.data) return null; const orders = params.data.orders; const sessions = params.data.sessions; return (orders && sessions) ? (orders/sessions) * 100: null; } },
        { headerName: "TACoS", field: "tacos", sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(1)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const cost = params.data.cost; const sales = params.data.sales; return (cost && sales) ? (cost / sales) * 100 : null; } },
      ]
    },
    {
      headerName: 'Last 30 Days',
      children: Array.from({ length: 30 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i - 2); // Subtract i+2 to start from yesterday
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        return { 
          headerName: formattedDate, 
          field: `_${i + 1}`, 
          sortable: true,  filter: 'agNumberColumnFilter', width: 100,
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 5 ? undefined : 'open',
          aggFunc: 'sum',
          valueGetter: params => params.data ? params.data[`_${i + 1}`] : undefined,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
        };
      }),
    },
    {
      headerName: 'Last 24 Months',
      children: [
      { headerName: "MTD Sales", field: "mtd_sales", sortable: 'agNumberColumnFilter',  filter: true, width: 100, cellStyle: { textAlign: 'right' }, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',  aggFunc: 'sum' },
      { headerName: 'YoY Delta', field: 'deltaYoY', sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: params => ({  textAlign: 'right',  color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black'  }),
        valueGetter: params => { if (!params.data) return null; return (params.data._l1 || 0) - (params.data._l13 || 0); },
        valueFormatter: params => { if (params.value == null) return ''; const formattedValue = Math.abs(params.value).toLocaleString('en-US');
        return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
        }, aggFunc: 'sum', headerTooltip: 'Last Month vs 12 Months Prior', 
      },  
      { headerName: 'MoM Delta', field: 'deltaYoY', sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: params => ({  textAlign: 'right',  color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black'  }),
      valueGetter: params => { if (!params.data) return null; return (params.data._l1 || 0) - (params.data._l2 || 0); },
      valueFormatter: params => { if (params.value == null) return ''; const formattedValue = Math.abs(params.value).toLocaleString('en-US');
      return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
      }, aggFunc: 'sum', headerTooltip: 'compares last month to the one before that', 
      },
      ...Array.from({ length: 24 }, (_, i) => {
        const date = new Date();
        date.setFullYear(date.getFullYear(), date.getMonth() - (i + 1), 1); 
        const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' });
        return { 
          headerName: formattedDate, 
          field: `_l${i + 1}`,
          sortable: true, 
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 4 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
        };
      }),
    ]},
    {
      headerName: 'Return Ratio',
      children: [
        { headerName: "L1 Refunds", field: "L1_refunds", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
        { headerName: "L1 Units Sold", field: "L1_units_sold", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
        createReturnRatioColumn("L1", "L1_ReturnRatio"),
        createReturnRatioColumn("L2", "L2_ReturnRatio", 'open'),
        createReturnRatioColumn("L3", "L3_ReturnRatio", 'open'),
        createReturnRatioColumn("L4", "L4_ReturnRatio", 'open'),
        createReturnRatioColumn("L5", "L5_ReturnRatio", 'open'),
        createReturnRatioColumn("L6", "L6_ReturnRatio", 'open'),
        createReturnRatioColumn("L7", "L7_ReturnRatio", 'open'),
        createReturnRatioColumn("L8", "L8_ReturnRatio", 'open'),
        createReturnRatioColumn("L9", "L9_ReturnRatio", 'open'),
        createReturnRatioColumn("L10", "L10_ReturnRatio", 'open'),
        createReturnRatioColumn("L11", "L11_ReturnRatio", 'open'),
        createReturnRatioColumn("L12", "L12_ReturnRatio", 'open')
      ]
    },
    {
      headerName: 'Custom Metrics',
      children: [
        {
          headerName: 'Max Last 6 Months',
          field: 'max_last_6_months',
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 150,
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            const months = ['_l1', '_l2', '_l3', '_l4', '_l5', '_l6'].map(key => params.data[key] || 0);
            return Math.max(...months);
          },
          valueFormatter: params => params.value != null ? params.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
        },
        {
          headerName: 'Difference Last Month and Max',
          field: 'diff_last_month_max',
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 200,
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            const lastMonth = params.data._l1 || 0;
            const months = ['_l2', '_l3', '_l4', '_l5', '_l6', '_l7'].map(key => params.data[key] || 0);
            const max = Math.max(...months);
            return lastMonth - max;
          },
          valueFormatter: params => params.value != null ? params.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
        }
      ]
    },
    {
      headerName: 'N60 Stock',
      children: Array.from({ length: 60 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() + i + 1); // Add i+1 to start from tomorrow
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        return { 
          headerName: formattedDate, 
          field: `day_${i + 1}`, 
          sortable: true, 
          filter: 'agNumberColumnFilter', 
          width: 100,
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 5 ? undefined : 'open',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
          cellClass: 'n60-stock-column',
        };
      })
    },   
    {
      headerName: 'Pricing',
      children: [
        { headerName: "Last Keepa Update", field: "lastPriceChange", sortable: true,filter: 'agDateColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, 
        valueFormatter: params => { if (params.data && params.data.currentPrice !== undefined) {
            const currentPrice = params.data.currentPrice; return currentPrice > 0 && params.value
              ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : ''; } return ''; },  columnGroupShow: 'open' },
        { headerName: "Keepa Price", field: "currentPrice", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, 
        valueFormatter: params => {
          if (params.value !== undefined) {
            const value = params.value;
            if (value < 0) {
              return '';
            }
            return value ? value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '';
          }
          return '';
        },
        aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Live Price", field: "your_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          cellStyle: { textAlign: 'right' },
          cellClass: params => params.data && params.data.your_price !== params.data.reqd_price ? 'ag-cell-red' : '',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', 
          aggFunc: 'sum' 
        },
        { headerName: "Reqd. Price", field: "reqd_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', aggFunc: 'sum' },
        { headerName: "Dynamic Price", field: "dynamic_price", sortable: true, filter: 'agNumberColumnFilter', width: 130, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', aggFunc: 'sum' },
        // { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 320},
        { headerName: "Price Delta", sortable: true, filter: 'agNumberColumnFilter', width: 120,
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data || !params.data.your_price || !params.data.reqd_price) return null;
            return params.data.your_price - params.data.reqd_price;
          },
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '',
          columnGroupShow: 'open',
          aggFunc: 'sum'
        },
        { headerName: "GST", field: "GST1", sortable: true, filter: true, width: 100, cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' ,
        valueFormatter: params => params.value ? new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(params.value) : '', },
        { headerName: "COGS", field: "calc_cogs", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Referral Fee", field: "estimated_referral_fee_per_unit", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Fulfillment Fee", field: "estimated_fulfillment_fee", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum', cellClass: params => params.data && params.data.estimated_fulfillment_fee > params.data.expected_fees ? 'ag-cell-red' : '' },
        { headerName: "Short LTSF Fee", field: "Short_LTSF_Fee", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Long LTSF Fee", field: "Long_LTSF_Fee", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Expected Fulfillment Fee", field: "expected_fees", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Monthly Impact", field: "monthly_impact", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data || !params.data.estimated_fulfillment_fee || !params.data.expected_fees) return null;
            const { estimated_fulfillment_fee: fee, expected_fees: expected, _l1 } = params.data;
            return fee > expected ? (_l1 * (fee - expected)) : 0;
          },
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) : '',
          columnGroupShow: 'open',
          aggFunc: 'sum'
        },
        { headerName: "GM1", field: "GM1", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' , headerTooltip: 'YourPrice - GST - COGs - Referral - Fulfilment fee' },
        { headerName: "GM1 %",field: "GM_Perc",sortable: true,filter: 'agNumberColumnFilter',width: 110,cellStyle: { textAlign: 'right' },
        valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${params.value.toFixed(0)} %`; } return ''; },
        valueGetter: params => { if (!params.data) return null; const GM1 = params.data.GM1; const your_price = params.data.your_price; return (GM1 && your_price) ? (GM1/your_price) * 100: null; }, columnGroupShow: 'open' },
        { headerName: "Longest Side", field: "longest_side", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Median Side", field: "median_side", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Shortest Side", field: "shortest_side", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Len & Girth", field: "length_and_girth", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Unit of Dims", field: "unit_of_dimension", sortable: true, filter: true, width: 125, columnGroupShow: 'open' },
        { headerName: "Package Wt.", field: "item_package_weight", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', columnGroupShow: 'open', aggFunc: 'sum' },
        { headerName: "Unit of Wt.", field: "unit_of_weight", sortable: true, filter: true, width: 125, columnGroupShow: 'open' },
        { headerName: "Size Tier", field: "product_size_tier", sortable: true, filter: true, width: 125, columnGroupShow: 'open' },
      ]
    },
  ];

const fulfilmentColumns = [
  "country_code", "sku", "Line", "dri", "sales_channel", "asin", "region", "channel", "size", "color", 
  "pcs_pack", "production", "l7_ma", "total_stock", "estimated_fulfillment_fee", 
  "expected_fees", "monthly_impact", "longest_side", "median_side", "shortest_side", "length_and_girth", 
  "unit_of_dimension", "item_package_weight", "unit_of_weight", "product_size_tier"
];

const ltsfColumns = [
  "country_code", "sku", "Line", "dri", "sales_channel", "asin", "region", "channel", "size", "color",
  "pcs_pack", "production", "launch_date", "status", "notes", "l7_ma", "p7_ma",
  "total_stock", "doc", "inbound_qty", "inbound_date", "last_sold_days",
  "risk_flag", "ma_flag", "inv_at_risk", "inv_short_ltsf", "inv_long_ltsf",
  "impressions", "clicks", "clicks_perc", "cost", "sales7d", "orders7d", "ctr", "ads_cvr", "acos",
  "w2_page_view", "w3_page_view", "w2_sessions", "w3_sessions", "w2_cvr", "w3_cvr",
  "_1", "_2", "_3", "_4", "_5", "_6", "_7", "_8", "_9", "_10", "_11", "_12", "_13", "_14", "_15", "_16", "_17", "_18", "_19", "_20", "_21", "_22", "_23", "_24", "_25", "_26", "_27", "_28", "_29", "_30",
  "mtd_sales", "_l1", "_l2", "_l3", "_l4", "_l5", "_l6", "_l7", "_l8", "_l9", "_l10", "_l11", "_l12", "_l13", "_l14", "_l15", "_l16", "_l17", "_l18", "_l19", "_l20", "_l21", "_l22", "_l23", "_l24",
  "your_price", "reqd_price", "dynamic_price"
];

console.log('Defining column sets...');

export const columnSets = {
  default: cospColumns,
  fulfilmentFee: cospColumns.map(group => {
    console.log('Processing group:', group.headerName);
    
    // If the group has children, filter them
    if (group.children) {
      const filteredChildren = group.children.filter(col => {
        console.log('Checking column:', col.field);
        const isIncluded = fulfilmentColumns.includes(col.field);
        console.log(`Column ${col.field} included: ${isIncluded}`);
        return isIncluded;
      });
      
      // Only include the group if it has any matching children
      if (filteredChildren.length > 0) {
        return {
          ...group,
          children: filteredChildren
        };
      }
    }
    return null;
  }).filter(group => group !== null), // Remove empty groups
  ltsf: cospColumns.map(group => {
    // If the group has children, filter them
    if (group.children) {
      const filteredChildren = group.children.filter(col => 
        ltsfColumns.includes(col.field)
      );
      
      // Only include the group if it has any matching children
      if (filteredChildren.length > 0) {
        return {
          ...group,
          children: filteredChildren
        };
      }
    }
    return null;
  }).filter(group => group !== null) // Remove empty groups
};

console.log('Column sets defined:', columnSets);