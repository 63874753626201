import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Button, Tabs, Tab } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columns as horizontalColumns } from './CampaignsHorizontalColumns';
import { columns as verticalColumns } from './CampaignsVerticalColumns';
import { CampaignsDetailGrid } from './CampaignsDetailGrid';
import QuickFilter from '../../components/global/QuickFilter';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const Campaigns = () => {
  // State management for grid data and UI controls
  const [rowData, setRowData] = useState([]); // Main grid data
  const [verticalData, setVerticalData] = useState([]); // Data for nested view
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const [activeTab, setActiveTab] = useState(0); // 0: Nested, 1: Horizontal, 2: Vertical

  // Detail grid renderer for nested view
  const detailCellRenderer = useMemo(() => {
    return (props) => {
      // Filter vertical data to show only rows matching the master row's campaign_id
      const campaignData = verticalData.filter(
        item => item.campaign_id === props.data.campaign_id
      );
      return <CampaignsDetailGrid data={campaignData} />;
    };
  }, [verticalData]);

  // Data fetching function that handles both horizontal and vertical data
  const fetchData = useCallback(() => {
    const endpoint = activeTab === 0 ? 
      '/api/campaigns/get_campaigns_vertical' : 
      '/api/campaigns/get_campaigns_horizontal';
    
    console.log(`Initiating Campaigns data fetch from ${endpoint}`);
    fetch(endpoint)
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          console.log('Number of rows received:', data.length);
          if (activeTab === 0) {
            setVerticalData(data);
          } else {
            setRowData(data);
          }
        } else {
          throw new Error('Received data is not an array or is empty');
        }
      })
      .catch(error => {
        console.error('Error fetching Campaigns data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, [activeTab]);

  // Initial data load and handling tab changes
  useEffect(() => {
    document.title = "Campaigns | Encasa HQ";
    fetchData();
    // Load vertical data for nested view if not already loaded
    if (activeTab === 0 && verticalData.length === 0) {
      fetch('/api/campaigns/get_campaigns_vertical')
        .then(response => response.json())
        .then(data => setVerticalData(data))
        .catch(error => console.error('Error fetching vertical data:', error));
    }
  }, [fetchData, activeTab, verticalData.length]);

  // Grid utility functions
  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((filter) => {
    setAppliedQuickFilter(filter);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Grid configuration based on active tab
  const getGridOptions = () => {
    if (activeTab === 0) {
      // Configuration for nested view
      return {
        masterDetail: true,
        detailCellRenderer: detailCellRenderer,
        detailRowHeight: 300,
        isRowMaster: (dataItem) => {
          return true; // All rows can be expanded
        },
        detailRowAutoHeight: false,
        suppressMaintainUnsortedOrder: true,
        columnDefs: [
          // Expand/collapse column configuration
          {
            field: '',
            maxWidth: 50,
            pinned: 'left',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: (params) => '',
            },
            sortable: false,
            filter: false,
            lockPosition: true,
            suppressMovable: true,
          },
          // Apply consistent column sizing to horizontal columns
          ...horizontalColumns.map(col => ({
            ...col,
            width: col.width || 120,
            minWidth: col.minWidth || 100,
            suppressSizeToFit: true
          }))
        ],
        suppressColumnVirtualisation: true,
        suppressRowVirtualisation: false,
      };
    }
    // Configuration for horizontal and vertical views
    return {
      columnDefs: activeTab === 1 ? horizontalColumns : verticalColumns,
    };
  };

  // Render component
  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header section with title and controls */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Campaigns</Typography>
        <Box display="flex" alignItems="center">
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV}
          >
            Export CSV
          </Button>
        </Box>
      </Box>

      {/* View selection tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Nested View" />
          <Tab label="Horizontal View" />
          <Tab label="Vertical View" />
        </Tabs>
      </Box>

      {/* Main grid area */}
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            {...getGridOptions()}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
            quickFilterText={appliedQuickFilter}
          />
        </div>
      )}
    </div>
  );
};

export default Campaigns; 