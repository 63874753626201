export const columns = [
  { field: 'country_code', headerName: 'Country', width: 100, pinned: 'left' },
  { field: 'line', headerName: 'Line', width: 120, pinned: 'left' },
  { field: 'dri', headerName: 'DRI', width: 120, pinned: 'left' },
  { field: 'campaign_name', headerName: 'Campaign Name', width: 300, pinned: 'left' },
// 30 columns for the last 30 days
  ...[...Array(30)].map((_, i) => ({
    headerName: `L${i + 1}`,
    children: [
      { field: `spend_l_${i + 1}`, headerName: 'Spend', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value === null ? '' : Number(params.value).toFixed(0) },
      { field: `sales_l_${i + 1}`, headerName: 'Sales', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value === null ? '' : Number(params.value).toFixed(0) },
      { field: `acos_l_${i + 1}`, headerName: 'ACOS', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value === null ? '' : `${(Number(params.value) * 100).toFixed(0)}%` },
    ],
  })),
];

columns.sort((a, b) => (a.field === 'spend_l_1' && b.field === 'spend_l_1') ? b.value - a.value : 0);