import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflow: 'auto',
};

const LABEL_OPTIONS = [
  'material',
  'color',
  'design',
  'size',
  'feature',
  'generic',
  'product',
  'use_case',
  'brand',
  'seasonal',
  'other'
];

const RELEVANCE_OPTIONS = [
  'Yes',
  'No',
  'High',
  'Unclassified'
];

const AddModifierModal = ({ open, handleClose, selectedCountry, selectedLine, onSubmit }) => {
  const [formData, setFormData] = useState({
    modifier: '',
    country: selectedCountry || '',
    line: selectedLine || '',
    label: '',
    relevance: 'Yes',
  });

  React.useEffect(() => {
    setFormData(prev => ({
      ...prev,
      country: selectedCountry,
      line: selectedLine
    }));
  }, [selectedCountry, selectedLine]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedCountry || !selectedLine) {
        throw new Error('Country and Line must be selected before adding a modifier');
      }

      console.log('Submitting form data:', formData);
      
      const response = await fetch('/api/modifier_classifier/add_modifier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          country: selectedCountry,
          line: selectedLine,
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        console.error('Server response:', result);
        throw new Error(result.error || 'Failed to add modifier');
      }
      
      handleClose();
      
      setFormData({ 
        ...formData, 
        modifier: '', 
        label: '', 
        relevance: 'Yes' 
      });
      
      onSubmit(result);
    } catch (error) {
      console.error('Error adding modifier:', error);
      alert(error.message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" mb={2}>
          Add New Modifier
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              required
              label="Modifier"
              name="modifier"
              value={formData.modifier}
              onChange={handleChange}
              autoFocus
            />
            
            <FormControl required>
              <InputLabel>Label</InputLabel>
              <Select
                value={formData.label}
                label="Label"
                name="label"
                onChange={handleChange}
              >
                {LABEL_OPTIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl required>
              <InputLabel>Relevance</InputLabel>
              <Select
                value={formData.relevance}
                label="Relevance"
                name="relevance"
                onChange={handleChange}
              >
                {RELEVANCE_OPTIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={!formData.modifier || !formData.label || !formData.relevance}
              >
                Add Modifier
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddModifierModal; 