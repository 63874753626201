const AGGrid_Options = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { padding: '0 4px' },
    headerClass: 'ag-header-compact',
    suppressMenu: true,
  },
  enableRangeSelection: true,
  multiSortKey: "ctrl",
  pagination: true,
  paginationPageSize: 50,
  paginationPageSizeSelector: [50, 100, 500, 1000],
  paginationNumberFormatter: (params) => {
    return '[' + params.value.toLocaleString() + ']';
  },
  rowHeight: 25,
  headerHeight: 30,
  suppressHorizontalScroll: false,
  animateRows: true,
  rowSelection: "multiple",
  cacheQuickFilter: true,
  grandTotalRow: "bottom",
  suppressHeaderContextMenu: false,
  suppressMenuHide: false
};

export default AGGrid_Options; 