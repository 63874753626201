import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, useTheme, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import { columns } from './modifierColumns';
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getTableData } from '../../utils/gridUtils';
import AddModifierModal from './AddModifierModal';

const Modifiers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');
  const [modifiersData, setModifiersData] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const gridRef = useRef(null);

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const countryParam = params.get('country');
    const lineParam = params.get('line');

    // First fetch countries and lines
    fetchCountriesAndLines().then(() => {
      // After fetching, set the values from URL if they exist
      if (countryParam) {
        setSelectedCountry(countryParam);
      }
      if (lineParam) {
        setSelectedLine(lineParam);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCountry && selectedLine) {
      fetchModifiersData();
    }
  }, [selectedCountry, selectedLine]);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const fetchCountriesAndLines = async () => {
    try {
      const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      setCountries(uniqueCountries);
      setLines([...new Set(data.map(item => item.line))]);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
      setError(error.message);
    }
  };

  const fetchModifiersData = async () => {
    try {
      const response = await fetch(
        `/api/modifier_classifier/get_data?country=${selectedCountry}&line=${selectedLine}`,
        fetchOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setModifiersData(data.modifier_data);
      setError(null);
    } catch (error) {
      console.error('Error fetching modifiers data:', error);
      setError(error.message);
      setModifiersData([]);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
  };

  const handleQuickFilterChange = useCallback((filter) => {
    setQuickFilterText(filter);
  }, []);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleCopyTableJson = () => {
    if (!gridRef.current || !gridRef.current.api) return;
    
    const tableData = getTableData(gridRef.current.api);
    navigator.clipboard.writeText(JSON.stringify(tableData, null, 2))
      .then(() => {
        console.log('Table JSON copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy table JSON:', err);
      });
  };

  const handleOpenModal = () => {
    if (!selectedCountry || !selectedLine) {
      alert('Please select both Country and Line before adding a new modifier');
      return;
    }
    setIsModalOpen(true);
  };

  const handleAddNewModifier = async (result) => {
    try {
      // Refresh the grid data
      await fetchModifiersData();
      
      // Show success message
      alert('Modifier added successfully');
    } catch (error) {
      console.error('Error refreshing modifiers data:', error);
      alert('Modifier was added but the display may not be up to date. Please refresh the page.');
    }
  };

  return (
    <div style={{ padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Keyword Modifiers</Typography>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Country"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {countries.map(country => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Line</InputLabel>
            <Select
              value={selectedLine}
              onChange={handleLineChange}
              label="Line"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {lines.map(line => (
                <MenuItem key={line} value={line}>{line}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <QuickFilter onFilterChange={handleQuickFilterChange} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          size="small"
          sx={{ mr: 1 }}
          disabled={!selectedCountry || !selectedLine}
        >
          Add New Modifier
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCopyTableJson}
          size="small"
          startIcon={<ContentCopyIcon />}
          sx={{ mr: 1 }}
        >
          Copy Table JSON
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleExportCSV}
          size="small"
        >
          Export CSV
        </Button>
      </Box>

      <AddModifierModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        selectedCountry={selectedCountry}
        selectedLine={selectedLine}
        onSubmit={handleAddNewModifier}
      />

      {error ? (
        <Typography color="error" variant="h6">Error: {error}</Typography>
      ) : (
        <div style={{ flexGrow: 1, width: '100%' }} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={modifiersData}
            columnDefs={columns}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
            quickFilterText={quickFilterText}
          />
        </div>
      )}
    </div>
  );
};

export default Modifiers; 