export const columns = [
  { field: 'country_code', headerName: 'Country', width: 100, pinned: 'left' },
  { field: 'line', headerName: 'Line', width: 120, pinned: 'left' },
  { field: 'dri', headerName: 'DRI', width: 120, pinned: 'left' },
  { field: 'campaign_name', headerName: 'Campaign Name', width: 300, pinned: 'left' },
  { field: 'campaign_id', headerName: 'Campaign ID', width: 150, pinned: 'left' },
  { 
    field: 'metric', 
    headerName: 'Metric', 
    width: 100, 
    pinned: 'left',
    cellStyle: params => null
  },
  
  // Dynamic columns for last 30 days
  ...[...Array(30)].map((_, i) => ({
    field: `l-${i + 1}`,
    headerName: `L${i + 1}`,
    width: 100,
    cellStyle: { textAlign: 'right' },
    valueFormatter: params => {
      if (!params.value || !params.data || params.value === null) return '';
      const value = Number(params.value);
      const metric = params.data.metric;
      
      if (!metric) return value.toFixed(0);
      
      switch(metric.toLowerCase()) {
        case 'acos':
        case 'cvr':
          return value ? `${(value * 100).toFixed(1)}%` : '';
          
        case 'spend':
        case 'sales':
        case 'budget':
          return value ? value.toFixed(0) : '0';
          
        case 'impressions':
        case 'clicks':
        case 'orders':
        case 'units':
          return value ? value.toFixed(0) : '0';
          
        default:
          return value ? value.toString() : '';
      }
    }
  }))
]; 