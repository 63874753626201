import { Box, Button, TextField, MenuItem, Typography, LinearProgress, useTheme } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import Header from "../../components/Header";
import { DataGrid } from '@mui/x-data-grid';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../../theme";

const getProgressBarColor = (score) => {
  if (score <= 3) return "#f44336"; // Red
  if (score <= 7) return "#ff9800"; // Orange
  return "#4caf50"; // Green
};

const SEOModule = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [country, setCountry] = useState("");
  const [asin, setAsin] = useState("");
  const [countries, setCountries] = useState([]);
  const [title, setTitle] = useState("");
  const [bulletPoints, setBulletPoints] = useState("");
  const [description, setDescription] = useState("");
  const [backendKeywords, setBackendKeywords] = useState("");
  const [seoScore, setSeoScore] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentFetched, setContentFetched] = useState(false);
  const [line, setLine] = useState("");
  const [mklData, setMklData] = useState([]);
  const [frankensteinData, setFrankensteinData] = useState([]);
  const [missingKeywords, setMissingKeywords] = useState("");

  const mklColumns = [
    { field: 'keywords', headerName: 'Keywords', flex: 2 },
    { field: 'category', headerName: 'Category', flex: 1 },
    { field: 'search_vol', headerName: 'Search Volume', flex: 1, type: 'number' },
  ];

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('/api/seo/get_countries');
        const data = await response.json();
        if (Array.isArray(data)) {
          setCountries(data);
        }
      } catch (err) {
        setError("Failed to fetch countries");
      }
    };
    fetchCountries();
  }, []);

  const handleFetchContent = async () => {
    if (!country || !asin || !line) return;
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/seo/fetch_content', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ country, asin, line })
      });

      const data = await response.json();
      if (response.ok) {
        setTitle(data.title || '');
        setBulletPoints(data.bullet_points.filter(bp => bp).join('\n') || '');
        setDescription(data.description || '');
        setBackendKeywords(data.backend_keywords || '');
        setMklData(data.mkl_data.map((item, index) => ({ ...item, id: index })));
        setContentFetched(true);
      } else {
        setError(data.error || 'Failed to fetch content');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckSEO = useCallback(async () => {
    setLoading(true);
    setError(null);
    setSeoScore(null);
    setDetails(null);

    try {
      const response = await fetch('/api/seo/calculate_seo_score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country,
          asin,
          title,
          bullet_points: bulletPoints.split('\n'),
          description,
          backend_keywords: backendKeywords
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Failed to calculate SEO score');
      
      setSeoScore(data.seo_score);
      setDetails(data.details);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [country, asin, title, bulletPoints, description, backendKeywords]);

  const processKeywords = useCallback(() => {
    if (!mklData.length) return [];

    // Get all words from MKL keywords
    const words = mklData
      .map(item => item.keywords.toLowerCase())
      .join(' ')
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(/\s+/)
      .filter(word => word.length > 0);

    // Count frequency
    const wordFrequency = {};
    words.forEach(word => {
      wordFrequency[word] = (wordFrequency[word] || 0) + 1;
    });

    // Create unique word list with frequency
    const uniqueWords = [...new Set(words)];

    // Check presence in title, bullet points, description, and backend keywords
    const contentToCheck = [
      title.toLowerCase(),
      bulletPoints.toLowerCase(),
      description.toLowerCase(),
      backendKeywords.toLowerCase()
    ].join(' ');

    return uniqueWords.map((word, index) => ({
      id: index,
      ngram: word,
      frequency: wordFrequency[word],
      inTitle: title.toLowerCase().includes(word),
      present: contentToCheck.includes(word)
    }));
  }, [mklData, title, bulletPoints, description, backendKeywords]);

  // Update Frankenstein data when content changes
  useEffect(() => {
    if (contentFetched) {
      const processedData = processKeywords();
      setFrankensteinData(processedData);
    }
  }, [contentFetched, title, bulletPoints, description, backendKeywords, mklData, processKeywords]);

  // Update the effect to sort by frequency
  useEffect(() => {
    if (frankensteinData.length) {
      const missing = frankensteinData
        .filter(word => !word.present) // Get words that are not present
        .sort((a, b) => b.frequency - a.frequency) // Sort by frequency in descending order
        .map(word => word.ngram)
        .join(', ');
      setMissingKeywords(missing);
    }
  }, [frankensteinData]);

  const frankensteinColumns = [
    {
      field: 'ngram',
      headerName: 'N-gram',
      flex: 1,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      sort: 'desc',
    },
    {
      field: 'inTitle',
      headerName: 'In Title',
      flex: 1,
      cellRenderer: params => params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
    },
    {
      field: 'present',
      headerName: 'Present',
      flex: 1,
      cellRenderer: params => params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
    }
  ];

  const renderMetricsDetails = () => (
    <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
      <Typography>Keyword Usage: {details.KU}%</Typography>
      <Typography>Keyword Density: {details.KD}%</Typography>
      <Typography>Keywords in Title: {details.KIT}%</Typography>
      <Typography>Keywords in First 100: {details.KIF}%</Typography>
      <Typography>Readability: {details.R}%</Typography>
    </Box>
  );

  const renderProgressBar = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" textAlign="center" gutterBottom>
        Progress
      </Typography>
      <Box position="relative">
        <LinearProgress
          variant="determinate"
          value={(seoScore / 10) * 100}
          sx={{
            height: 20,
            borderRadius: 5,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: getProgressBarColor(seoScore),
            },
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box m="20px" height="100vh">
      <Header title="SEO Module" subtitle="Calculate SEO scores for your content" />
      
      <Box display="flex" flexDirection="column" gap={3} mx="20px" mt={4}>
        {/* Main content row */}
        <Box display="flex" gap={4}>
          {/* Left side - Form Controls */}
          <Box display="flex" flexDirection="column" gap={3} flex={1}>
            {/* Selection Controls */}
            <Box display="flex" gap={2}>
              <TextField
                select
                label="Country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setContentFetched(false);
                }}
                fullWidth
              >
                {countries.map((c) => (
                  <MenuItem key={c} value={c}>{c}</MenuItem>
                ))}
              </TextField>

              <TextField
                label="ASIN"
                value={asin}
                onChange={(e) => {
                  setAsin(e.target.value.toUpperCase());
                  setContentFetched(false);
                }}
                placeholder="Enter ASIN"
                fullWidth
              />

              <TextField
                label="Line"
                value={line}
                onChange={(e) => {
                  setLine(e.target.value.toUpperCase());
                  setContentFetched(false);
                }}
                placeholder="Enter Line"
                fullWidth
              />

              <Button
                variant="contained"
                onClick={handleFetchContent}
                disabled={!country || !asin || !line || loading}
                sx={{ minWidth: '120px' }}
              >
                Fetch
              </Button>
            </Box>

            {/* Content Fields */}
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              multiline
              rows={2}
              disabled={!contentFetched}
            />

            <TextField
              label="Bullet Points (one per line)"
              value={bulletPoints}
              onChange={(e) => setBulletPoints(e.target.value)}
              multiline
              rows={6}
              fullWidth
              disabled={!contentFetched}
            />

            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              fullWidth
              disabled={!contentFetched}
            />

            <TextField
              label="Backend Keywords"
              value={backendKeywords}
              onChange={(e) => setBackendKeywords(e.target.value)}
              multiline
              rows={4}
              fullWidth
              disabled={!contentFetched}
            />

            {contentFetched && (
              <Button
                variant="contained"
                onClick={handleCheckSEO}
                disabled={loading || !title || !bulletPoints || !description || !backendKeywords}
                size="large"
                sx={{ py: 2 }}
              >
                {loading ? "Calculating..." : "Check SEO Score"}
              </Button>
            )}

            {/* SEO Score Display */}
            {seoScore !== null && (
              <Box sx={{ mt: 3, p: 3, border: '1px solid #ccc', borderRadius: 1 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                  SEO Score: {seoScore}%
                </Typography>
                {renderProgressBar()}
                {details && renderMetricsDetails()}
              </Box>
            )}
          </Box>

          {/* Right side - Tables */}
          {contentFetched && (
            <Box sx={{ flex: 1, minWidth: '500px', display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* MKL Table */}
              <Box>
                <Typography variant="h5" gutterBottom>
                  MKL Keywords (Top 100 by Search Volume)
                </Typography>
                <div className="ag-theme-alpine" style={{ width: '100%', height: '400px' }}>
                  <AgGridReact
                    rowData={mklData}
                    columnDefs={mklColumns}
                    defaultColDef={{
                      flex: 1,
                      minWidth: 100,
                      sortable: true,
                      filter: true,
                    }}
                    pagination={true}
                    paginationPageSize={20}
                    {...AGGrid_Options}
                    sideBar={AGGrid_Sidebar}
                  />
                </div>
              </Box>

              {/* Frankenstein Table */}
              <Box>
                <Typography variant="h5" gutterBottom>
                  FRANKENSTEIN Keyword Processor
                </Typography>
                <div className="ag-theme-alpine" style={{ width: '100%', height: '400px' }}>
                  <AgGridReact
                    rowData={frankensteinData}
                    columnDefs={frankensteinColumns}
                    defaultColDef={{
                      flex: 1,
                      minWidth: 100,
                      sortable: true,
                      filter: true,
                    }}
                    pagination={true}
                    paginationPageSize={20}
                    {...AGGrid_Options}
                    sideBar={AGGrid_Sidebar}
                  />
                </div>

                {/* Missing Keywords Text Box */}
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Missing Keywords
                  </Typography>
                  <TextField
                    value={missingKeywords}
                    multiline
                    rows={3}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      backgroundColor: colors.primary[400],
                      '& .MuiInputBase-input': {
                        color: colors.grey[100],
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {error && (
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SEOModule;
