import { useState } from "react";
import { Box, Button, Typography, Paper, useTheme, TextField, Tabs, Tab, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import axios from "axios";
import { handleUploadClick_Weekly,handleUpload_HeliumnJS, handleUploadClick_MonthlyJS } from './etl_business_report_cols.js';

const ETLBusinessReport = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [monthDate, setMonthDate] = useState("");
  const [country, setCountry] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  
  // Separate country states for weekly and monthly
  const [weeklyCountry, setWeeklyCountry] = useState("");
  const [monthlyCountry, setMonthlyCountry] = useState("");
  const [heliumCountry, setHeliumCountry] = useState("");
  const [heliumLine, setHeliumLine] = useState("");

  // Separate handlers for weekly and monthly country changes
  const handleWeeklyCountryChange = (event) => {
    setWeeklyCountry(event.target.value);
  };

  const handleMonthlyCountryChange = (event) => {
    setMonthlyCountry(event.target.value);
  };

  const handleHeliumCountryChange = (event) => {
    setHeliumCountry(event.target.value);
  };

  const handleHeliumLineChange = (event) => {
    setHeliumLine(event.target.value);
  };

  // Get current date for weekly report
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getCurrentMonday = () => {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;
    const monday = new Date(today.setDate(first));
    return monday.toISOString().split('T')[0]; };

    const getCurrentSunday = () => {
      const today = new Date();
      const first = today.getDate() - today.getDay();
      const sunday = new Date(today.setDate(first));
      return sunday.toISOString().split('T')[0]; };  

  const [weekStartDate, setWeekStartDate] = useState(getCurrentSunday());
  const [weekEndDate, setWeekEndDate] = useState(getCurrentDate());

  const countries = ["US","CA", "UK", "ES", "FR", "IT", "DE", "IN", "JP"];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setMessage("");
  };

  const handleWeekStartDateChange = (event) => {
    setWeekStartDate(event.target.value);
  };

  const handleWeekEndDateChange = (event) => {
    setWeekEndDate(event.target.value);
  };

  const handleMonthDateChange = (event) => {
    setMonthDate(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadWeekly = async () => {
    handleUploadClick_Weekly({ file, weekStartDate,  weekEndDate,  weeklyCountry,  setIsLoading,
        setMessage,
        setFile,
      });
    };
  const handleUploadHelium = async () => {
    handleUpload_HeliumnJS({ 
      file, 
      heliumCountry,
      heliumLine,
      setIsLoading,
      setMessage,
      setFile,
    });
  };  

  const handleUploadMonthly = async () => {
    handleUploadClick_MonthlyJS({ file, monthlyCountry,  setIsLoading,
      setMessage,
      setFile,
    });
  };  

  const handleTabChange = (event, newValue) => { 
    setActiveTab(newValue);
    setFile(null);
    setMessage("");
    setWeeklyCountry("");  // Reset weekly country
    setMonthlyCountry("");  // Reset monthly country
    // Reset file inputs
    const weeklyInput = document.getElementById("file-upload-weekly");
    const monthlyInput = document.getElementById("file-upload-monthly");
    if (weeklyInput) weeklyInput.value = "";
    if (monthlyInput) monthlyInput.value = "";
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      minWidth: '150px',
    },
  }));
  
  const StyledTab = styled(Tab)(({ theme }) => ({
    marginRight: theme.spacing(2),
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: 'inherit',
    }
  }));

  const renderForm = () => {
    const commonTextFieldProps = {
      sx: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: colors.grey[100] },
        },
        "& .MuiInputLabel-root": {
          color: colors.grey[100],
        },
      }
    };

    const uploadButton = (
      <Button
        variant="contained"
        onClick={activeTab === 0 
          ? handleUploadWeekly 
          : activeTab === 1 
            ? handleUploadMonthly 
            : handleUploadHelium}
        disabled={isLoading}
        sx={{
          backgroundColor: colors.blueAccent[700],
          "&:hover": { backgroundColor: colors.blueAccent[800] },
        }}
      >
        {isLoading ? "Uploading..." : "Upload"}
      </Button>
    );

    if (activeTab === 0) {
      return (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          {/* <TextField
            type="date"
            label="Week Start Date"
            value={weekStartDate}
            onChange={handleWeekStartDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { style: { color: colors.grey[100] } }
            }}
            sx={commonTextFieldProps.sx}
          /> 

          <TextField
            type="date"
            label="Week End Date"
            value={weekEndDate}
            onChange={handleWeekEndDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { style: { color: colors.grey[100] } }
            }}
            sx={commonTextFieldProps.sx}
            helperText="Make sure Week Date Starts from Sunday in Amazon Report"
          /> */}

          <TextField
            select
            label="Country"
            value={weeklyCountry}
            onChange={handleWeeklyCountryChange}
            required
            error={!weeklyCountry}
            sx={commonTextFieldProps.sx}
            helperText="Make sure Week Date Starts from Sunday in Amazon Report"
          >
            {countries.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>

          <input
            id="file-upload-weekly"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{
              padding: "10px",
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: "4px",
              backgroundColor: colors.primary[400],
              color: colors.grey[100],
            }}
          />

          {uploadButton}
        </Box>
      );
    } if (activeTab === 1) {
      return (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <TextField 
            label="Month Date"
            value={monthDate}
            onChange={handleMonthDateChange}
            placeholder="YYYY-MM"
            InputProps={{
              inputProps: { 
                style: { color: colors.grey[100] },
                pattern: "\\d{4}-\\d{2}-\\d{2}"
              }
            }}
            sx={commonTextFieldProps.sx}
            helperText="Format: YYYY-MM"
          />

          <TextField
            select
            label="Country"
            value={monthlyCountry}
            onChange={handleMonthlyCountryChange}
            required
            error={!monthlyCountry}
            sx={commonTextFieldProps.sx}
          >
            {countries.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>

          <input
            id="file-upload-monthly"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{
              padding: "10px",
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: "4px",
              backgroundColor: colors.primary[400],
              color: colors.grey[100],
            }}
          />

          {uploadButton}
        </Box>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <TextField
            select
            label="Country"
            value={heliumCountry}
            onChange={handleHeliumCountryChange}
            required
            error={!heliumCountry}
            sx={commonTextFieldProps.sx}
          >
            {countries.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>

          <TextField
            label="Line"
            value={heliumLine}
            onChange={handleHeliumLineChange}
            required
            error={!heliumLine}
            sx={commonTextFieldProps.sx}
          />

          <input
            id="file-upload-Helium"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{
              padding: "10px",
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: "4px",
              backgroundColor: colors.primary[400],
              color: colors.grey[100],
            }}
          />

          {uploadButton}
        </Box>
      );
    }
  };

  return (
    <Box m="20px">
      <StyledTabs 
        value={activeTab} 
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="primary"
      >
        <StyledTab label="Weekly Business Report" />
        <StyledTab label="Monthly Business Report" />
        <StyledTab label="H10 Cerebro Report" />
      </StyledTabs>

      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" color={colors.grey[100]} fontWeight="bold" mb="20px">
            Upload Report
          </Typography>
        </Box>

        <Paper
          elevation={3}
          sx={{
            p: 1,
            backgroundColor: colors.primary[400],
            maxWidth: 600,
            ml: "-10",
          }}
        >
          {renderForm()}
          {message && (
            <Typography
              color={
                message.includes("❌") ? colors.redAccent[500] : 
                message.includes("⏳") ? colors.grey[300] :
                colors.greenAccent[500]
              }
              mt={2}
              ml={2}
              mb={2}
              sx={{
                whiteSpace: 'pre-line',
                fontFamily: 'monospace'
              }}
            >
              {message}
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ETLBusinessReport; 