import axios from 'axios';

export const handleUploadClick_Weekly = async ({
    file, weekStartDate, weekEndDate, weeklyCountry,
    setIsLoading,
    setMessage,
    setFile,
  }) => {
    if (!file) {
      setMessage("Please select a file first!");
      return;
    }
  
    if (!weeklyCountry) {
      setMessage("Please select a country!");
      return;
    }
  
    setIsLoading(true);
    setMessage("⏳ Uploading file, please wait...");
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("week_start_date", weekStartDate);
    formData.append("week_end_date", weekEndDate);
    formData.append("country", weeklyCountry);
  
    try {
      console.log("Uploading weekly with country:", weeklyCountry);
      const response = await axios.post("/api/upload_business_report_weekly_to_bq", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      setMessage(`✅ Success! \n
          • File Uploaded: ${file.name}\n
          • Rows Pushed to BigQuery: ${response.data.rows_pushed_to_bq} rows\n`);
  
      setFile(null);
      document.getElementById("file-upload-weekly").value = "";
    } catch (error) {
      setMessage("❌ Error: " + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

export const handleUploadClick_MonthlyJS = async ({
  file, monthlyCountry, monthDate,
  setIsLoading,
  setMessage,
  setFile,
}) => {
    if (!file) {
      setMessage("Please select a file first!");
      return;
    }

    if (!monthlyCountry) {
      setMessage("Please select a country!");
      return;
    }

    setIsLoading(true);
    setMessage("⏳ Uploading file, please wait...");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("month_date", monthDate);
    formData.append("country", monthlyCountry);

    try {
      console.log("Uploading monthly with country:", monthlyCountry);
      const response = await axios.post("/api/upload_business_report_monthly_bq", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      setMessage(`✅ Success! \n
          • File Uploaded: ${file.name}\n
          • Rows Pushed to BigQuery: ${response.data.rows_pushed_to_bq} rows\n`);
      
      setFile(null);
      document.getElementById("file-upload-monthly").value = "";
    } catch (error) {
      setMessage("❌ Error: " + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
};

export const handleUpload_HeliumnJS = async ({
    file, heliumCountry, heliumLine,
    setIsLoading,
    setMessage,
    setFile,
  }) => {
    if (!file) {
      setMessage("Please select a file first!");
      return;
    }
  
    if (!heliumCountry) {
      setMessage("Please select a country!");
      return;
    }

    if (!heliumLine) {
      setMessage("Please enter a line!");
      return;
    }
  
    setIsLoading(true);
    setMessage("⏳ Uploading file, please wait...");
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("country", heliumCountry);
    formData.append("line", heliumLine);
  
    try {
      console.log("Uploading helium with country:", heliumCountry, "and line:", heliumLine);
      const response = await axios.post("/api/upload_heliumn_bq_test", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      setMessage(`✅ Success! \n
          • File Uploaded: ${file.name}\n
          • Rows Processed: ${response.data.rows_processed}\n
          • Rows Pushed to BigQuery: ${response.data.rows_pushed_to_bq}`);
  
      setFile(null);
      document.getElementById("file-upload-Helium").value = "";
    } catch (error) {
      setMessage("❌ Error: " + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };  