export const columns = [
  {
    headerName: "Date",
    field: "updated_at",
    sortable: true,
    filter: 'agDateColumnFilter',
    width: 160,
    valueFormatter: params => {
      if (!params.value) return '';
      const date = new Date(params.value);
      return date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    }
  },
  { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
  { headerName: "Sku", field: "sku", sortable: true, filter: true, width: 120 },
  { headerName: "Asin", field: "asin", sortable: true, filter: true, width: 120 },
  { headerName: "Line", field: "line", sortable: true, filter: true, width: 120 },
  { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 120 },
  { headerName: "Note", field: "content", sortable: true, filter: true, flex: 1, wrapText: true, autoHeight: true }
]; 