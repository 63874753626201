export const columns = [
  {
    field: "modifier",
    headerName: "Modifier",
    width: 200,
    suppressSizeToFit: true,
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    suppressSizeToFit: true,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: [
        'material',
        'color',
        'design',
        'size',
        'feature',
        'generic',
        'product',
        'use_case',
        'brand',
        'seasonal',
        'other'
      ]
    },
    onCellValueChanged: (params) => {
      fetch('/api/modifier_classifier/update_modifier_type', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: params.data.country,
          line: params.data.line,
          modifier: params.data.modifier,
          type: params.newValue
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update type');
        }
        return response.json();
      })
      .catch(error => {
        console.error('Error updating type:', error);
        params.node.setDataValue('type', params.oldValue);
      });
    }
  },
  {
    field: "relevance",
    headerName: "Relevance",
    width: 150,
    suppressSizeToFit: true,
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Unclassified', 'No', 'Yes', 'High']
    },
    cellStyle: (params) => {
      switch (params.value) {
        case 'High':
          return { backgroundColor: '#e6ffe6' };
        case 'Yes':
          return { backgroundColor: '#f0fff0' };
        case 'No':
          return { backgroundColor: '#fff0f0' };
        default:
          return null;
      }
    },
    onCellValueChanged: (params) => {
      fetch('/api/modifier_classifier/update_modifier_relevance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: params.data.country,
          line: params.data.line,
          modifier: params.data.modifier,
          relevance: params.newValue
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update relevance');
        }
        return response.json();
      })
      .catch(error => {
        console.error('Error updating relevance:', error);
        params.node.setDataValue('relevance', params.oldValue);
      });
    }
  }
]; 