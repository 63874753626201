import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { columns as verticalColumns } from './CampaignsVerticalColumns';

export const CampaignsDetailGrid = ({ data }) => {
  // Grid configuration options
  const detailGridOptions = useMemo(() => ({
    // Filter out columns that are already shown in the master grid
    // and remove pinning from remaining columns
    columnDefs: verticalColumns
      .filter(col => !['country_code', 'line', 'dri', 'campaign_name', 'campaign_id'].includes(col.field))
      .map(col => ({
        ...col,
        pinned: undefined
      })),

    // Default column properties
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      suppressMovable: true, // Prevent column reordering
    },

    // Grid behavior configuration
    domLayout: 'normal',
    suppressHorizontalScroll: false,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    suppressRowVirtualisation: false,
    
    // Row and header sizing
    rowHeight: 25, // Compact row height
    headerHeight: 30, // Slightly taller header
  }), []);

  // Render the detail grid
  return (
    <div 
      className="ag-theme-alpine" 
      style={{ 
        height: '280px', // Fixed height for detail section
        width: '100%', 
        marginTop: '10px',
        overflowX: 'auto', // Enable horizontal scrolling
        overflowY: 'auto'  // Enable vertical scrolling
      }}
    >
      <AgGridReact
        rowData={data}
        {...detailGridOptions}
        suppressLoadingOverlay={true}
        suppressNoRowsOverlay={true}
      />
    </div>
  );
}; 