import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, useTheme, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import { columns } from './keywordSpendsColumns';
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getTableData } from '../../utils/gridUtils';

const KeywordSpends = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');
  const [keywordData, setKeywordData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  
  const gridRef = useRef(null);

  useEffect(() => {
    console.log("Component mounted, initializing data fetch");
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const countryParam = params.get('country');
    const lineParam = params.get('line');

    console.log(`URL parameters - country: ${countryParam}, line: ${lineParam}`);

    // First fetch countries and lines
    fetchCountriesAndLines().then(() => {
      // After fetching, set the values from URL if they exist
      if (countryParam) {
        console.log(`Setting country from URL: ${countryParam}`);
        setSelectedCountry(countryParam);
      }
      if (lineParam) {
        console.log(`Setting line from URL: ${lineParam}`);
        setSelectedLine(lineParam);
      }
      setIsInitialized(true);
    });
  }, []); // Only run on mount

  useEffect(() => {
    if (!isInitialized) {
      return; // Wait for initialization
    }

    // Fetch data after initialization
    console.log(`Fetching data - country: ${selectedCountry}, line: ${selectedLine}`);
    fetchKeywordData();
  }, [isInitialized, selectedCountry, selectedLine]);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const fetchCountriesAndLines = async () => {
    try {
      console.log("Fetching countries and lines data");
      const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      const uniqueLines = [...new Set(data.map(item => item.line))];
      console.log(`Retrieved ${uniqueCountries.length} countries and ${uniqueLines.length} lines`);
      setCountries(uniqueCountries);
      setLines(uniqueLines);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
      setError(error.message);
    }
  };

  const fetchKeywordData = async () => {
    try {
      console.log("Starting keyword data fetch");
      setLoading(true);
      let url = '/api/ads/get_keyword_spends';
      
      // Add filters to URL if they exist
      const params = new URLSearchParams();
      if (selectedCountry) params.append('country', selectedCountry);
      if (selectedLine) params.append('line', selectedLine);
      
      const queryString = params.toString();
      if (queryString) {
        url += `?${queryString}`;
      }
      
      console.log(`Fetching keyword data from: ${url}`);
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`Retrieved ${data.length} keyword records`);
      setKeywordData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching keyword data:', error);
      setError(error.message);
      setKeywordData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (event) => {
    console.log(`Country selection changed to: ${event.target.value}`);
    setSelectedCountry(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('country', event.target.value);
    } else {
      params.delete('country');
    }
    if (selectedLine) {
      params.set('line', selectedLine);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const handleLineChange = (event) => {
    console.log(`Line selection changed to: ${event.target.value}`);
    setSelectedLine(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('line', event.target.value);
    } else {
      params.delete('line');
    }
    if (selectedCountry) {
      params.set('country', selectedCountry);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const handleQuickFilterChange = useCallback((filter) => {
    setQuickFilterText(filter);
  }, []);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleCopyTableJson = () => {
    if (!gridRef.current || !gridRef.current.api) return;
    
    const tableData = getTableData(gridRef.current.api);
    navigator.clipboard.writeText(JSON.stringify(tableData, null, 2))
      .then(() => {
        console.log('Table JSON copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy table JSON:', err);
      });
  };

  const handleCellValueChanged = async (params) => {
    if (params.column.colId === 'label') {
      try {
        console.log('Updating label with data:', {
          country: params.data.country,
          line: params.data.line,
          keyword: params.data.search_term,
          new_label: params.newValue
        });

        const response = await fetch('/api/keyword_classifier/update_keyword_label', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            country: params.data.country,
            line: params.data.line,
            keyword: params.data.search_term,
            new_label: params.newValue
          })
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        console.log('Label updated successfully');
        // Update local data to reflect changes
        params.data.label_source = 'user';
        params.data.confidence_score = 1;
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: ['label', 'label_source', 'confidence_score'],
          force: true
        });
      } catch (error) {
        console.error('Error updating keyword label:', error);
        setError(`Failed to update label: ${error.message}`);
        // Revert the cell value
        params.node.setDataValue('label', params.oldValue);
      }
    }
  };

  return (
    <div style={{ padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Keyword Spends</Typography>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Country"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {countries.map(country => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Line</InputLabel>
            <Select
              value={selectedLine}
              onChange={handleLineChange}
              label="Line"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {lines.map(line => (
                <MenuItem key={line} value={line}>{line}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <QuickFilter onFilterChange={handleQuickFilterChange} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCopyTableJson}
          size="small"
          startIcon={<ContentCopyIcon />}
          sx={{ mr: 1 }}
        >
          Copy Table JSON
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleExportCSV}
          size="small"
        >
          Export CSV
        </Button>
      </Box>

      {error ? (
        <Typography color="error" variant="h6">Error: {error}</Typography>
      ) : (
        <div style={{ flexGrow: 1, width: '100%' }} className="ag-theme-alpine">
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <AgGridReact
              ref={gridRef}
              rowData={keywordData}
              columnDefs={columns}
              {...AGGrid_Options}
              sideBar={AGGrid_Sidebar}
              quickFilterText={quickFilterText}
              onCellValueChanged={handleCellValueChanged}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default KeywordSpends; 