export const createReturnsAnalysisColumns_Weekly = (data) => {
    const weekNumbers = [...new Set(data.map(item => parseInt(item.week)))].sort((a, b) => b - a); 
  
    const yearWeeks = [...new Set(data.map(item => item.week))]
    .filter(week => week != null) // Remove null/undefined values
    .sort((a, b) => {
      try {
        const [yearA, weekA] = a.toString().split('-').map(Number);
        const [yearB, weekB] = b.toString().split('-').map(Number);
        
        if (isNaN(yearA) || isNaN(weekA) || isNaN(yearB) || isNaN(weekB)) {
          console.warn('Invalid year-week format:', { a, b });
          return 0;
        }

        // Compare years first, then weeks
        if (yearA !== yearB) return yearB - yearA;
        return weekB - weekA;
      } catch (error) {
        console.error('Error processing year-week:', { a, b, error });
        return 0;
      }
    });

    const columns = [
      { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Line", field: "line", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true }, 
    ];
  
    yearWeeks.forEach((yearWeek) => {
      try {
        if (!yearWeek) return; // Skip if yearWeek is null/undefined
        
        const [year, week] = yearWeek.toString().split('-');
        if (!year || !week) {
          console.warn('Invalid year-week format:', yearWeek);
          return;
        }
      const children = [
      {headerName: `Orders`, field: `quantity_ordered_${yearWeek}`,sortable: true, filter: 'agNumberColumnFilter', width: 110, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' },aggFunc: 'sum', },
      {headerName: `Returns`,   field: `quantity_returned_${yearWeek}`,  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle:{textAlign:'right' }, aggFunc: 'sum', },
      {headerName: `Return Ratio`,   field: `returned_ratio_${yearWeek}`,  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, 
        valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US')} %` : '', cellStyle:{textAlign:'right' }, aggFunc: 'sum', },
    ];

    columns.push({
      headerName: `Week ${week} (${year})`,
      children: children
    });
    } catch (error) {
    console.error('Error processing column for year-week:', yearWeek, error);
  }
  });

  return columns;
  };


  export const createReturnsAnalysisColumns_Monthly = (data) => {
    const monthOrder = ["Dec", "Nov", "Oct", "Sep", "Aug", "Jul", "Jun", "May", "Apr", "Mar", "Feb", "Jan"];
    
    const sortedMonths = [...new Set(data
      .map(item => item.month)
      .filter(month => month !== null && month !== undefined))]
      .sort((a, b) => {
        try {
          const [monthA, yearA] = a.split('-');
          const [monthB, yearB] = b.split('-');

          const numYearA = parseInt(yearA);
          const numYearB = parseInt(yearB);
          
          if (numYearA !== numYearB) {
            return numYearB - numYearA;
          }
  
          // If years are the same, sort by month using monthOrder array
          return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
        } catch (error) {
          console.error('Error processing month-year:', { a, b, error });
          return 0;
        }
      });
  
    const columns = [
      { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Line", field: "line", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true }, 
    ];
  
    sortedMonths.forEach(month => {
      try {
        if (!month) return;
      const children = [
        {headerName: `Orders`, field: `quantity_ordered_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
        {headerName: `Returns`, field: `quantity_returned_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
        {headerName: `Return Ratio`, field: `returned_ratio_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US')} %` : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
      ];

    columns.push({
      headerName: `${month}`,
      children: children,
    });
  } catch (error) {
    console.error('Error processing column for month:', month, error);
  }
  });

return columns;
};
