// Add some todos here:
// 1. Fix progress bar so that it corresponds with the upload in Python
// 3. Add ability to refresh a folder

import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useTheme, Modal, TextField, Button, CircularProgress, Alert, Snackbar, LinearProgress, ImageList, ImageListItem, IconButton } from "@mui/material";
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { tokens } from "../../theme";
import ImagePreview from '../dam/ImagePreview';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

const QUICK_RENAME_OPTIONS = [
  'MAIN.jpg',
  'PT01.jpg',
  'PT02.jpg',
  'PT03.jpg',
  'PT04.jpg',
  'PT05.jpg',
  'PT06.jpg',
  'SWCH.jpg'
];

const COLUMNS = [
  { title: 'Line', width: 150 },
  { title: 'Country', width: 150 },
  { title: 'SKU', width: 150 },
  { title: 'Files', flex: 1 }
];

const ROW_HEIGHT = 28; // Match this with the tree node height

const getParentPath = (path) => {
  const normalizedPath = path.replace(/\\/g, '/');
  const parentPath = normalizedPath.split('/').slice(0, -1).join('/');
  return parentPath;
};

const LazyImageListItem = React.memo(({ file, onClick }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <ImageListItem 
      sx={{
        cursor: 'pointer',
        border: '1px solid #eee',
        borderRadius: '4px',
        overflow: 'hidden',
        height: '450px !important',
        '&:hover': {
          opacity: 0.8,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }
      }}
      onClick={onClick}
    >
      {file.isImage ? (
        <Box sx={{ position: 'relative', width: '100%', height: '400px' }}>
          {!isLoaded && !error && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#f5f5f5',
                zIndex: 1
              }}
            >
              <CircularProgress size={40} />
            </Box>
          )}
          <img
            src={`${file.url}`}
            alt={file.title}
            loading="lazy"
            style={{ 
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              backgroundColor: '#f5f5f5',
              opacity: isLoaded ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out'
            }}
            onLoad={() => {
              console.log('Image loaded:', file.url);
              setIsLoaded(true);
            }}
            onError={(e) => {
              console.error(`Error loading image: ${file.url}`);
              setError(true);
              e.target.onerror = null;
              e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTl2LTE0aC0xOHYxNGgydjJoMTR2LTJoMnptLTktNmMxLjY1NCAwIDMgMS4zNDYgMyAzcy0xLjM0NiAzLTMgMy0zLTEuMzQ2LTMtMyAxLjM0Ni0zIDMtM3ptMC0yYy0yLjc2MSAwLTUgMi4yMzktNSA1czIuMjM5IDUgNSA1IDUtMi4yMzkgNS01LTIuMjM5LTUtNS01eiIvPjwvc3ZnPg==';
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: 'rgba(255,255,255,0.9)',
              p: 1,
              zIndex: 2
            }}
          >
            <Typography 
              sx={{ 
                textAlign: 'center',
                color: '#000',
                fontSize: '0.9rem',
                wordBreak: 'break-word'
              }}
            >
              {file.title}
            </Typography>
          </Box>
        </Box>
      ) : (
        // Non-image file display remains the same
        <Box
          sx={{
            width: '100%',
            height: '400px',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InsertDriveFileIcon sx={{ fontSize: 64, color: '#666', mb: 2 }} />
          <FileDownloadIcon sx={{ fontSize: 32, color: '#666' }} />
          <Typography 
            sx={{ 
              p: 1, 
              textAlign: 'center',
              color: '#000',
              fontSize: '0.9rem',
              wordBreak: 'break-word'
            }}
          >
            {file.title}
          </Typography>
        </Box>
      )}
    </ImageListItem>
  );
});

const LAYOUT = {
  treeIndent: 24,
  treeIcon: 24,
  treeWidth: 300,
  columnWidths: {
    line: 150,
    country: 150,
    sku: 150
  }
};

const DAMFolder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [treeData, setTreeData] = useState([]);
  const [error, setError] = useState(null);
  const [previewInfo, setPreviewInfo] = useState({ show: false, src: '', x: 0, y: 0 });
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [bulkFolderModal, setBulkFolderModal] = useState(false);
  const [bulkFolderNames, setBulkFolderNames] = useState('');
  const [currentFolderPath, setCurrentFolderPath] = useState(null);
  const [renameModal, setRenameModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [duplicateFolderNames, setDuplicateFolderNames] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(new Set());
  const [uploadFiles, setUploadFiles] = useState([]);
  const [copiedFiles, setCopiedFiles] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false);
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const shouldRefresh = useRef(true);
  const [rootPath, setRootPath] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [folderSelectionMode, setFolderSelectionMode] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState(new Set());
  const [viewFilesModal, setViewFilesModal] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [notification, setNotification] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState(new Set());

  // Add new ref for scroll synchronization
  const treeRef = useRef(null);
  const columnsRef = useRef(null);

  // Add scroll handler function
  const handleScroll = (event) => {
    const { target } = event;
    if (target === treeRef.current) {
      columnsRef.current.scrollTop = target.scrollTop;
    } else if (target === columnsRef.current) {
      treeRef.current.scrollTop = target.scrollTop;
    }
  };

  useEffect(() => {
    if (!shouldRefresh.current) {
      return;
    }

    fetch('/api/dam/get_folder_structure')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setTreeData(data);
        } else {
          console.error("Invalid tree data structure received:", data);
          setError("Invalid data structure received from server");
        }
        shouldRefresh.current = false;
      })
      .catch(error => {
        console.error('Error fetching folder structure:', error);
        setError(error.message);
        shouldRefresh.current = false;
      });
  }, []);

  useEffect(() => {
    // Fetch the root path from the backend
    fetch('/api/dam/get_dam_root')
      .then(response => response.json())
      .then(data => {
        if (data.root_path) {
          setRootPath(data.root_path);
        } else {
          console.error("Failed to fetch root path:", data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching root path:", error);
      });
  }, []);

  const handleSelect = (selectedKeys, info) => {
    if (selectionMode) {
      handleFileSelection(info.node);
    } else if (folderSelectionMode) {
      handleFolderSelection(info.node);
    } else if (info.node.isLeaf) {
      if (info.node.isImage) {
        // Get path segments after 'dam' directory
        const pathSegments = info.node.key.split(/[\/\\]/);
        const damIndex = pathSegments.indexOf('dam');
        const relevantSegments = pathSegments.slice(damIndex + 1);
        const remoteUrl = `http://files.encasaxo.com/${relevantSegments.join('/')}`;
        window.open(remoteUrl, '_blank');
      } else {
        // For non-image files, keep existing download behavior
        const link = document.createElement('a');
        link.href = info.node.url;
        link.download = info.node.title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleMouseEnter = (info) => {
    if (info.node.isLeaf && info.node.isImage) {
      // Get path segments after 'dam' directory
      const pathSegments = info.node.key.split(/[\/\\]/);
      const damIndex = pathSegments.indexOf('dam');
      const relevantSegments = pathSegments.slice(damIndex + 1);
      const remoteUrl = `http://files.encasaxo.com/${relevantSegments.join('/')}`;
      setPreviewInfo({
        show: true,
        src: remoteUrl,
        x: info.event.clientX + 10,
        y: info.event.clientY + 10,
      });
    }
  };

  const handleMouseLeave = () => {
    setPreviewInfo({ show: false, src: '', x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    if (previewInfo.show) {
      setPreviewInfo(prev => ({
        ...prev,
        x: e.clientX + 10,
        y: e.clientY + 10,
      }));
    }
  };

  // Custom icon renderer - updated for black icons
  const iconRenderer = (props) => {
    if (!props) return null;
    
    const { isLeaf, expanded } = props;
    // Get node from props.data for rc-tree
    const node = props.data;
    
    if (folderSelectionMode && !isLeaf && node?.key) {
      return selectedFolders.has(node.key) ? 
        <CheckBoxIcon sx={{ fontSize: 20, color: '#1976d2', mr: 1 }} /> :
        <CheckBoxOutlineBlankIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} />;
    }
    
    if (selectionMode && isLeaf && node?.key) {
      return selectedFiles.has(node.key) ? 
        <CheckBoxIcon sx={{ fontSize: 20, color: '#1976d2', mr: 1 }} /> :
        <CheckBoxOutlineBlankIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} />;
    }
    
    if (isLeaf) {
      const isCopied = copiedFiles.some(file => file.key === node?.key);
      const isImage = node?.isImage;
      
      if (isImage) {
        return <ImageIcon sx={{ 
          fontSize: 20, 
          color: isCopied ? '#2e7d32' : // green color for copied files
                 (node?.key && selectedFiles.has(node.key)) ? '#1976d2' : '#000', 
          mr: 1 
        }} />;
      } else {
        return <InsertDriveFileIcon sx={{ 
          fontSize: 20, 
          color: isCopied ? '#2e7d32' : // green color for copied files
                 (node?.key && selectedFiles.has(node.key)) ? '#1976d2' : '#000', 
          mr: 1 
        }} />;
      }
    }
    
    return expanded ? 
      <FolderOpenIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} /> : 
      <FolderIcon sx={{ fontSize: 20, color: '#000', mr: 1 }} />;
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    const isRootNode = node.key === rootPath;
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      isRootNode: isRootNode
    });
    setSelectedNode(node);
    setCurrentFolderPath(node.key);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleOperation = async (operationFunc) => {
    setIsOperationInProgress(true);
    try {
      await operationFunc();
    } finally {
      setIsOperationInProgress(false);
    }
  };

  const handleBulkFolderCreate = async () => {
    if (!currentFolderPath || !bulkFolderNames.trim()) return;

    setIsOperationInProgress(true);
    try {
      const response = await fetch('/api/dam/create_bulk_folders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          parentPath: currentFolderPath,
          folderNames: bulkFolderNames.split('\n').filter(name => name.trim()),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);

      setTreeData(oldData => {
        const updateParentNode = (nodes) => {
          return nodes.map(node => {
            if (node.key === currentFolderPath) {
              return {
                ...node,
                children: [...(node.children || []), ...responseData.new_folders]
              };
            } else if (node.children) {
              return {
                ...node,
                children: updateParentNode(node.children)
              };
            }
            return node;
          });
        };
        return updateParentNode(oldData);
      });

      setBulkFolderModal(false);
      setBulkFolderNames('');
      handleCloseContextMenu();
      setCurrentFolderPath(null);
      setError(null);
      const folderCount = bulkFolderNames.split('\n').filter(name => name.trim()).length;
      setNotification({
        severity: 'success',
        message: `Successfully created ${folderCount} folders`
      });
    } catch (error) {
      console.error('Error in handleBulkFolderCreate:', error);
      setNotification({
        severity: 'error',
        message: `Failed to create folders: ${error.message}`
      });
    } finally {
      setIsOperationInProgress(false);
    }
  };

  const handleRename = async () => {
    if (!currentFolderPath || !newFolderName.trim()) return;

    setIsOperationInProgress(true);
    try {
      const response = await fetch('/api/dam/rename_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          folderPath: currentFolderPath,
          newName: newFolderName.trim(),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error);
      }

      // Update only the parent folder's tree
      const parentKey = getParentPath(currentFolderPath);
      setTreeData(oldData => 
        updateTreeNode(oldData, parentKey, responseData.updated_tree)
      );

      setRenameModal(false);
      setNewFolderName('');
      handleCloseContextMenu();
      setNotification({
        severity: 'success',
        message: `Successfully renamed ${selectedNode?.isLeaf ? 'file' : 'folder'} to "${newFolderName.trim()}"`
      });
    } catch (error) {
      console.error('Error renaming:', error);
      setNotification({
        severity: 'error',
        message: `Failed to rename ${selectedNode?.isLeaf ? 'file' : 'folder'}: ${error.message}`
      });
    } finally {
      setIsOperationInProgress(false);
    }
  };

  const handleDelete = async () => {
    if (!currentFolderPath) return;

    if (window.confirm(`Are you sure you want to delete the folder "${selectedNode?.title}" and all its contents?`.replace('"' + selectedNode?.title + '"', '**' + selectedNode?.title + '**'))) {
      setIsOperationInProgress(true);
      try {
        const response = await fetch('/api/dam/delete_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            folderPath: currentFolderPath,
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        console.log('Current folder path:', currentFolderPath);
        const parentKey = getParentPath(currentFolderPath);
        console.log('Parent key for update:', parentKey);
        console.log('Updated tree from server:', responseData.updated_tree);

        setTreeData(oldData => {
          const newData = updateTreeNode(oldData, parentKey, responseData.updated_tree);
          console.log('Tree data after update:', newData);
          return newData;
        });

        handleCloseContextMenu();
        setNotification({
          severity: 'success',
          message: `Successfully deleted folder "${selectedNode?.title}"`
        });
      } catch (error) {
        console.error('Error deleting folder:', error);
        setNotification({
          severity: 'error',
          message: `Failed to delete folder: ${error.message}`
        });
      } finally {
        setIsOperationInProgress(false);
      }
    }
  };

  const handleDuplicate = async () => {
    if (!currentFolderPath || !duplicateFolderNames.trim()) return;

    setIsOperationInProgress(true);
    try {
        const response = await fetch('/api/dam/duplicate_folder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                sourcePath: currentFolderPath,
                targetNames: duplicateFolderNames.split('\n').filter(name => name.trim()),
            }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        // Update only the parent folder's tree
        const parentKey = getParentPath(currentFolderPath);
        setTreeData(oldData => 
            updateTreeNode(oldData, parentKey, responseData.updated_tree)
        );

        setDuplicateModal(false);
        setDuplicateFolderNames('');
        handleCloseContextMenu();
        const folderCount = duplicateFolderNames.split('\n').filter(name => name.trim()).length;
        setNotification({
          severity: 'success',
          message: `Successfully duplicated folder to ${folderCount} locations`
        });
    } catch (error) {
        console.error('Error duplicating folder:', error);
        setNotification({
          severity: 'error',
          message: `Failed to duplicate folder: ${error.message}`
        });
    } finally {
        setIsOperationInProgress(false);
    }
  };

  // Add new handler for selection mode toggle
  const toggleSelectionMode = (e) => {
    e.preventDefault();
    setSelectionMode(!selectionMode);
    if (!selectionMode) {
      setSelectedFiles(new Set());
    }
  };

  // Add file selection handler
  const handleFileSelection = (node) => {
    if (node.isLeaf) {
      const newSelection = new Set(selectedFiles);
      if (newSelection.has(node.key)) {
        newSelection.delete(node.key);
      } else {
        newSelection.add(node.key);
      }
      setSelectedFiles(newSelection);
    }
  };

  // Add copy files handler
  const handleCopyFiles = () => {
    const filesList = Array.from(selectedFiles).map(key => {
      const node = findNodeByKey(treeData, key);
      return {
        key: node.key,
        url: node.url,
        title: node.title
      };
    });
    setCopiedFiles(filesList);
    setSelectionMode(false);
    setSelectedFiles(new Set());
  };

  // Helper function to find node by key
  const findNodeByKey = (nodes, key) => {
    for (const node of nodes) {
      if (node.key === key) return node;
      if (node.children) {
        const found = findNodeByKey(node.children, key);
        if (found) return found;
      }
    }
    return null;
  };

  // Add paste files handler
  const handlePasteFiles = async () => {
    if (!currentFolderPath || copiedFiles.length === 0) return;

    setIsOperationInProgress(true);
    try {
        const response = await fetch('/api/dam/paste_files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                targetPath: currentFolderPath,
                files: copiedFiles
            }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        // Update the tree with the new structure
        setTreeData(oldData => 
            updateTreeNode(oldData, currentFolderPath, responseData.updated_tree)
        );

        handleCloseContextMenu();
        setNotification({
          severity: 'success',
          message: `Successfully pasted ${copiedFiles.length} files`
        });
    } catch (error) {
        console.error('Error pasting files:', error);
        setNotification({
          severity: 'error',
          message: `Failed to paste files: ${error.message}`
        });
    } finally {
        setIsOperationInProgress(false);
    }
  };

  const handleFileDelete = async () => {
    if (!currentFolderPath) return;

    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        const response = await fetch('/api/dam/delete_file', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            filePath: currentFolderPath,
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        handleCloseContextMenu();
      } catch (error) {
        console.error('Error deleting file:', error);
        setError(`Failed to delete file: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    const treeNodes = document.querySelectorAll('.rc-tree-treenode');
    
    treeNodes.forEach(node => {
      const nodeKey = node.getAttribute('data-key');
      if (nodeKey) {
        node.setAttribute('data-selected', 
          selectedFiles.has(nodeKey).toString()
        );
        node.setAttribute('data-copied', 
          copiedFiles.some(file => file.key === nodeKey).toString()
        );
      }
    });
  }, [selectedFiles, copiedFiles]);

  // Add this function to update tree data
  const updateTreeNode = (oldTreeData, parentKey, newSubtree) => {
    const updateNode = (nodes) => {
      return nodes.map(node => {
        const normalizedNodeKey = node.key.replace(/\\/g, '/');
        const normalizedParentKey = parentKey.replace(/\\/g, '/');
        
        if (normalizedNodeKey === normalizedParentKey) {
          return {
            ...node,
            children: newSubtree[0].children
          };
        } else if (node.children) {
          return {
            ...node,
            children: updateNode(node.children)
          };
        }
        return node;
      });
    };
    
    return updateNode(oldTreeData);
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files).filter(file => {
      // Check file type
      if (!file.type.startsWith('image/')) {
        setUploadError({
          severity: 'error',
          message: `${file.name} is not an image file`
        });
        return false;
      }
      
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setUploadError({
          severity: 'error',
          message: `${file.name} is too large (max size: ${MAX_FILE_SIZE / (1024 * 1024)}MB)`
        });
        return false;
      }
      
      return true;
    });
    
    setUploadFiles(files);
    
    if (files.length > 0) {
      setUploadError({
        severity: 'success',
        message: `${files.length} file(s) selected`
      });
    }
  };

  const handleUpload = async () => {
    if (!uploadFiles.length || !currentFolderPath) return;

    setUploading(true);
    setUploadError(null);
    
    const formData = new FormData();
    formData.append('targetFolder', currentFolderPath);
    
    // Calculate total size for progress tracking
    const totalSize = uploadFiles.reduce((acc, file) => acc + file.size, 0);
    let uploadedSize = 0;
    
    uploadFiles.forEach(file => {
      formData.append('files', file);
      setUploadProgress(prev => ({
        ...prev,
        [file.name]: 0
      }));
    });

    try {
      console.log('Starting upload to:', currentFolderPath);
      console.log('Number of files:', uploadFiles.length);
      
      const xhr = new XMLHttpRequest();
      
      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          uploadFiles.forEach(file => {
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: progress
            }));
          });
        }
      };
      
      // Create promise for XHR
      const uploadPromise = new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        xhr.onerror = () => reject(new Error('Upload failed'));
      });
      
      xhr.open('POST', '/api/dam/upload_files');
      xhr.responseType = 'json';
      xhr.send(formData);
      
      const response = await uploadPromise;
      
      if (!response) {
        throw new Error('No response from server');
      }

      // Update the tree with the new structure
      const parentKey = getParentPath(currentFolderPath);
      setTreeData(oldData => 
        updateTreeNode(oldData, parentKey, response.updated_tree)
      );

      setUploadModal(false);
      setUploadFiles([]);
      
      // Show success message
      setUploadError({ severity: 'success', message: 'Files uploaded successfully!' });
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError({ 
        severity: 'error', 
        message: `Upload failed: ${error.message}. Please try again.` 
      });
    } finally {
      setUploading(false);
      setTimeout(() => {
        setUploadProgress({});
      }, 2000);
    }
  };

  const handleDownloadFolder = async () => {
    if (!currentFolderPath) return;

    try {
      const response = await fetch('/api/dam/download_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          folderPath: currentFolderPath,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to download folder');
      }

      // Create a blob from the response
      const blob = await response.blob();
      
      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${selectedNode?.title || 'folder'}.zip`;
      
      // Trigger download
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading folder:', error);
      setError(`Failed to download folder: ${error.message}`);
    }
  };

  // Add new handler for folder selection mode toggle
  const toggleFolderSelectionMode = (e) => {
    e.preventDefault();
    setFolderSelectionMode(!folderSelectionMode);
    if (!folderSelectionMode) {
      setSelectedFolders(new Set());
    }
  };

  // Add folder selection handler
  const handleFolderSelection = (node) => {
    if (!node.isLeaf) {
      const newSelection = new Set(selectedFolders);
      if (newSelection.has(node.key)) {
        newSelection.delete(node.key);
      } else {
        newSelection.add(node.key);
      }
      setSelectedFolders(newSelection);
    }
  };

  // Add bulk delete handler
  const handleBulkFolderDelete = async () => {
    if (selectedFolders.size === 0) return;

    const selectedFolderNames = Array.from(selectedFolders).map(path => {
      const node = findNodeByKey(treeData, path);
      return node?.title || path;
    }).join('\n- ');

    if (window.confirm(`Are you sure you want to delete these ${selectedFolders.size} folders and all their contents? This action cannot be undone.\n\nSelected folders:\n- ${selectedFolderNames}`)) {
      setIsOperationInProgress(true);
      try {
        const response = await fetch('/api/dam/delete_folders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            folderPaths: Array.from(selectedFolders)
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        // Update the tree data with the new structure
        setTreeData(responseData.updated_tree);
        setFolderSelectionMode(false);
        setSelectedFolders(new Set());
        setNotification({
          severity: 'success',
          message: `Successfully deleted ${selectedFolders.size} folders`
        });
      } catch (error) {
        console.error('Error deleting folders:', error);
        setNotification({
          severity: 'error',
          message: `Failed to delete folders: ${error.message}`
        });
      } finally {
        setIsOperationInProgress(false);
      }
    }
  };

  // Add new handler for bulk paste
  const handleBulkPaste = async () => {
    if (selectedFolders.size === 0 || copiedFiles.length === 0) return;

    if (window.confirm(`Are you sure you want to paste ${copiedFiles.length} files into ${selectedFolders.size} folders?`)) {
      setIsOperationInProgress(true);
      try {
        const response = await fetch('/api/dam/paste_to_folders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            targetFolders: Array.from(selectedFolders),
            files: copiedFiles
          }),
        });

        const responseData = await response.json();
        if (!response.ok) throw new Error(responseData.error);

        // Update the tree data with the new structure
        setTreeData(responseData.updated_tree);
        setFolderSelectionMode(false);
        setSelectedFolders(new Set());
        setNotification({
          severity: 'success',
          message: `Successfully pasted ${copiedFiles.length} files into ${selectedFolders.size} folders`
        });
      } catch (error) {
        console.error('Error pasting files:', error);
        setNotification({
          severity: 'error',
          message: `Failed to paste files: ${error.message}`
        });
      } finally {
        setIsOperationInProgress(false);
      }
    }
  };

  const getAllFiles = (node) => {
    let files = [];
    if (node.children) {
      node.children.forEach(child => {
        if (child.isLeaf) {
          // Convert file path to remote URL for images
          let url = child.url;
          if (child.isImage) {
            const pathSegments = child.key.split(/[\/\\]/);
            const damIndex = pathSegments.indexOf('dam');
            const relevantSegments = pathSegments.slice(damIndex + 1);
            url = `http://files.encasaxo.com/${relevantSegments.join('/')}`;
          }
          files.push({
            url: url,
            title: child.title,
            isImage: child.isImage
          });
        } else {
          files = [...files, ...getAllFiles(child)];
        }
      });
    }
    return files;
  };

  const handleViewFiles = () => {
    if (selectedNode) {
      const files = getAllFiles(selectedNode);
      setCurrentFiles(files);
      setViewFilesModal(true);
      handleCloseContextMenu();
    }
  };

  // Update the flattenVisibleNodes function
  const flattenVisibleNodes = (treeData, expandedKeys = new Set()) => {
    const result = [];
    const traverse = (nodes, depth = 0) => {
      if (!nodes) return;
      nodes.forEach(node => {
        // Include node if:
        // 1. It's at root level (depth = 0)
        // 2. Its parent is expanded (which is implied by us reaching this node)
        result.push({
          line: node.line || '',
          country: node.country || '',
          sku: node.sku || '',
          title: node.title,
          isLeaf: node.isLeaf,
          key: node.key
        });
        
        // Traverse children if this node is expanded
        if (node.children && expandedKeys.has(node.key)) {
          traverse(node.children, depth + 1);
        }
      });
    };
    traverse(treeData);
    return result;
  };

  // Update the handleExpand function to properly track expanded state
  const handleExpand = (expandedKeysArray, { node, expanded }) => {
    const newExpandedKeys = new Set(expandedKeys);
    if (expanded) {
      // When expanding a node, add its key to expanded set
      newExpandedKeys.add(node.key);
    } else {
      // When collapsing a node, remove its key and all its children's keys
      const removeExpandedChildren = (nodeToCollapse) => {
        newExpandedKeys.delete(nodeToCollapse.key);
        if (nodeToCollapse.children) {
          nodeToCollapse.children.forEach(child => {
            removeExpandedChildren(child);
          });
        }
      };
      removeExpandedChildren(node);
    }
    setExpandedKeys(newExpandedKeys);
  };

  return (
    <div style={{ padding: '20px', height: '100vh', backgroundColor: 'white' }} onMouseMove={handleMouseMove}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ color: '#000' }}>DAM Folder Browser</Typography>
        <Box>
          {folderSelectionMode && selectedFolders.size > 0 && (
            <>
              <Button
                onClick={handleBulkFolderDelete}
                variant="contained"
                color="error"
                sx={{ mr: 2 }}
              >
                Delete Selected Folders ({selectedFolders.size})
              </Button>
              {copiedFiles.length > 0 && (
                <Button
                  onClick={handleBulkPaste}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  Paste into Selected Folders ({selectedFolders.size})
                </Button>
              )}
            </>
          )}
          {selectionMode && selectedFiles.size > 0 && (
            <Button
              onClick={handleCopyFiles}
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
            >
              Copy Selected ({selectedFiles.size})
            </Button>
          )}
          {copiedFiles.length > 0 && !selectionMode && !folderSelectionMode && (
            <Button
              onClick={() => setCopiedFiles([])}
              variant="outlined"
              color="error"
              sx={{ mr: 2 }}
            >
              Clear Copied ({copiedFiles.length})
            </Button>
          )}
          <Button
            onClick={toggleFolderSelectionMode}
            variant="outlined"
            color={folderSelectionMode ? "secondary" : "primary"}
            sx={{ mr: 2 }}
            disabled={selectionMode}
          >
            {folderSelectionMode ? "Cancel Folder Selection" : "Select Folders"}
          </Button>
          <Button
            onClick={toggleSelectionMode}
            variant="outlined"
            color={selectionMode ? "secondary" : "primary"}
            disabled={folderSelectionMode}
          >
            {selectionMode ? "Cancel Selection" : "Select Files"}
          </Button>
        </Box>
      </Box>
      
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <Box sx={{ 
          height: 'calc(100% - 60px)', // Adjust for header height
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Header Row */}
          <Box sx={{ 
            display: 'flex',
            borderBottom: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
            height: `${ROW_HEIGHT}px`,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            alignItems: 'center'
          }}>
            <Box sx={{ 
              width: LAYOUT.treeWidth, 
              px: 1,
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>Files</Box>
            <Box sx={{ 
              width: LAYOUT.columnWidths.line, 
              px: 1,
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>Line</Box>
            <Box sx={{ 
              width: LAYOUT.columnWidths.country, 
              px: 1,
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>Country</Box>
            <Box sx={{ 
              width: LAYOUT.columnWidths.sku, 
              px: 1,
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>SKU</Box>
          </Box>

          {/* Content Area */}
          <Box sx={{ 
            display: 'flex',
            flexGrow: 1,
            overflow: 'hidden'
          }}>
            {/* Tree Section */}
            <Box 
              ref={treeRef}
              onScroll={handleScroll}
              sx={{ 
                width: LAYOUT.treeWidth,
                borderRight: '1px solid #ddd',
                overflow: 'auto',
                '& .rc-tree': {
                  padding: 0,
                  '& .rc-tree-treenode': {
                    height: `${ROW_HEIGHT}px`,
                    padding: '0 !important',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #eee',
                    '&:hover': {
                      backgroundColor: '#f5f5f5'
                    }
                  },
                  '& .rc-tree-node-content-wrapper': {
                    minHeight: `${ROW_HEIGHT}px`,
                    height: `${ROW_HEIGHT}px`,
                    lineHeight: `${ROW_HEIGHT}px`,
                    padding: '0 4px',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1
                  },
                  '& .rc-tree-switcher': {
                    height: `${ROW_HEIGHT}px`,
                    lineHeight: `${ROW_HEIGHT}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 4px'
                  },
                  '& .rc-tree-indent-unit': {
                    width: LAYOUT.treeIndent
                  },
                  '& .rc-tree-iconEle': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '4px',
                    height: '100%'
                  }
                }
              }}
            >
              <Tree
                treeData={treeData}
                onSelect={handleSelect}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                defaultExpandAll={false}
                showIcon={true}
                icon={iconRenderer}
                switcherIcon={null}
                onRightClick={(info) => handleContextMenu(info.event, info.node)}
                showLine={true}
                checkable={false}
                selectable={true}
                motion={null}
                draggable={false}
                onExpand={handleExpand}
                titleRender={(nodeData) => (
                  <Box sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    pl: 1
                  }}>
                    {nodeData.title}
                  </Box>
                )}
              />
            </Box>

            {/* Data Columns Section */}
            <Box 
              ref={columnsRef}
              onScroll={handleScroll}
              sx={{ 
                flexGrow: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {flattenVisibleNodes(treeData, expandedKeys).map((node, index) => (
                <Box
                  key={node.key}
                  sx={{
                    display: 'flex',
                    borderBottom: '1px solid #eee',
                    minHeight: `${ROW_HEIGHT}px`,
                    height: `${ROW_HEIGHT}px`,
                    alignItems: 'center',
                    backgroundColor: selectedNode?.key === node.key ? '#f0f7ff' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#f5f5f5'
                    }
                  }}
                >
                  <Box sx={{ 
                    width: LAYOUT.columnWidths.line,
                    px: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    {node.line}
                  </Box>
                  <Box sx={{ 
                    width: LAYOUT.columnWidths.country,
                    px: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    {node.country}
                  </Box>
                  <Box sx={{ 
                    width: LAYOUT.columnWidths.sku,
                    px: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    {node.sku}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {/* Context Menu - updated styling */}
      {contextMenu && (
        <div
          style={{
            position: 'fixed',
            top: contextMenu.y,
            left: contextMenu.x,
            backgroundColor: '#000',
            border: `1px solid ${colors.grey[300]}`,
            borderRadius: '4px',
            padding: '8px',
            zIndex: 1000,
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <Button
            onClick={() => {
              setNewFolderName(selectedNode?.title || '');  // Prepopulate with current name
              setRenameModal(true);
              handleCloseContextMenu();
            }}
            startIcon={<EditIcon />}
            sx={{ 
              color: '#fff', 
              display: 'flex', 
              textAlign: 'left',
              width: '100%',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: colors.grey[800],
              }
            }}
          >
            Rename
          </Button>
          {selectedNode && !contextMenu.isRootNode && (
            <Button
              onClick={selectedNode?.isLeaf ? handleFileDelete : handleDelete}
              startIcon={<DeleteIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              Delete
            </Button>
          )}
          {!selectedNode?.isLeaf && (
            <>
              <Button
                onClick={() => {
                  setBulkFolderModal(true);
                  handleCloseContextMenu();
                }}
                startIcon={<DriveFileMoveIcon />}
                sx={{ 
                  color: '#fff', 
                  display: 'flex', 
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: colors.grey[800],
                  }
                }}
              >
                Add Bulk Folders
              </Button>
              <Button
                onClick={() => {
                  setDuplicateModal(true);
                  handleCloseContextMenu();
                }}
                startIcon={<ContentCopyIcon />}
                sx={{ 
                  color: '#fff', 
                  display: 'flex', 
                  textAlign: 'left',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    backgroundColor: colors.grey[800],
                  }
                }}
              >
                Duplicate to Multiple Folders
              </Button>
            </>
          )}
          {copiedFiles.length > 0 && !selectedNode?.isLeaf && (
            <Button
              onClick={handlePasteFiles}
              startIcon={<ContentPasteIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              Paste Files ({copiedFiles.length})
            </Button>
          )}
          {!selectedNode?.isLeaf && (
            <Button
              onClick={() => {
                setUploadModal(true);
                handleCloseContextMenu();
              }}
              startIcon={<CloudUploadIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              Upload Files
            </Button>
          )}
          {!selectedNode?.isLeaf && (
            <Button
              onClick={handleDownloadFolder}
              startIcon={<DownloadIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              Download as ZIP
            </Button>
          )}
          {!selectedNode?.isLeaf && (
            <Button
              onClick={handleViewFiles}
              startIcon={<VisibilityIcon />}
              sx={{ 
                color: '#fff', 
                display: 'flex', 
                textAlign: 'left',
                width: '100%',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: colors.grey[800],
                }
              }}
            >
              View Files
            </Button>
          )}
        </div>
      )}

      {/* Update Modal styling */}
      <Modal
        open={bulkFolderModal}
        onClose={() => setBulkFolderModal(false)}
        aria-labelledby="bulk-folder-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Create Bulk Folders
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={bulkFolderNames}
            onChange={(e) => setBulkFolderNames(e.target.value)}
            placeholder="Enter folder names (one per line)"
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={() => setBulkFolderModal(false)}
              sx={{ color: '#000' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleBulkFolderCreate}
              variant="contained"
              color="primary"
            >
              Create Folders
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Add Rename Modal */}
      <Modal
        open={renameModal}
        onClose={() => setRenameModal(false)}
        aria-labelledby="rename-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Rename {selectedNode?.isLeaf ? 'File' : 'Folder'}
          </Typography>
          <TextField
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder={`Enter new ${selectedNode?.isLeaf ? 'file' : 'folder'} name`}
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          
          {selectedNode?.isLeaf && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
                Quick Rename Options:
              </Typography>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1 
              }}>
                {QUICK_RENAME_OPTIONS.map((option) => (
                  <Button
                    key={option}
                    variant="outlined"
                    size="small"
                    onClick={() => setNewFolderName(option)}
                    sx={{
                      textTransform: 'none',
                      fontSize: '0.8rem',
                      minWidth: 0,
                      p: '4px 8px',
                      color: newFolderName === option ? 'white' : '#666',
                      backgroundColor: newFolderName === option ? '#1976d2' : 'transparent',
                      borderColor: '#666',
                      '&:hover': {
                        backgroundColor: newFolderName === option ? '#1565c0' : 'rgba(0, 0, 0, 0.04)',
                        borderColor: '#1976d2'
                      }
                    }}
                  >
                    {option}
                  </Button>
                ))}
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setRenameModal(false)} sx={{ color: '#000' }}>
              Cancel
            </Button>
            <Button onClick={handleRename} variant="contained" color="primary">
              Rename
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Add Duplicate Modal */}
      <Modal
        open={duplicateModal}
        onClose={() => setDuplicateModal(false)}
        aria-labelledby="duplicate-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Duplicate to Multiple Folders
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={duplicateFolderNames}
            onChange={(e) => setDuplicateFolderNames(e.target.value)}
            placeholder="Enter folder names (one per line)"
            sx={{
              mb: 2,
              '& .MuiInputBase-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[300],
                },
                '&:hover fieldset': {
                  borderColor: colors.grey[400],
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setDuplicateModal(false)} sx={{ color: '#000' }}>
              Cancel
            </Button>
            <Button onClick={handleDuplicate} variant="contained" color="primary">
              Duplicate
            </Button>
          </Box>
        </Box>
      </Modal>

      {previewInfo.show && (
        <ImagePreview
          src={previewInfo.src}
          style={{
            left: `${previewInfo.x}px`,
            top: `${previewInfo.y}px`,
          }}
        />
      )}

      {uploading && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 3,
            p: 2,
            zIndex: 1000,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Uploading Files...
          </Typography>
          {Object.entries(uploadProgress).map(([filename, progress]) => (
            <Box key={filename} sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {filename}
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{ height: 8, borderRadius: 4 }}
              />
            </Box>
          ))}
        </Box>
      )}

      <Snackbar
        open={!!uploadError}
        autoHideDuration={6000}
        onClose={() => setUploadError(null)}
      >
        <Alert 
          severity={uploadError?.severity || 'error'} 
          onClose={() => setUploadError(null)}
        >
          {uploadError?.message}
        </Alert>
      </Snackbar>

      <Modal
        open={uploadModal}
        onClose={() => {
          setUploadModal(false);
          setUploadFiles([]);
        }}
        aria-labelledby="upload-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#000', fontWeight: 'bold' }}>
            Upload Files
          </Typography>
          
          <Box
            sx={{
              border: '2px dashed #ccc',
              borderRadius: '4px',
              p: 3,
              mb: 3,
              textAlign: 'center',
              backgroundColor: '#fafafa',
              cursor: 'pointer',
              '&:hover': {
                borderColor: '#999'
              }
            }}
            onClick={() => document.getElementById('file-input').click()}
          >
            <input
              type="file"
              id="file-input"
              multiple
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: '#666', mb: 1 }} />
            <Typography variant="body1" sx={{ color: '#666' }}>
              Click to select files to upload
            </Typography>
            <Typography variant="body2" sx={{ color: '#666', mt: 1 }}>
              Maximum file size: {MAX_FILE_SIZE / (1024 * 1024)}MB
            </Typography>
          </Box>

          {uploadFiles.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: '#000' }}>
                Selected Files ({uploadFiles.length}):
              </Typography>
              <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                {uploadFiles.map((file, index) => (
                  <Typography key={index} variant="body2" sx={{ color: '#666' }}>
                    {file.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={() => {
                setUploadModal(false);
                setUploadFiles([]);
              }}
              sx={{ color: '#000' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              disabled={!uploadFiles.length || uploading}
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={viewFilesModal}
        onClose={() => setViewFilesModal(false)}
        aria-labelledby="view-files-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'white',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" component="h2" sx={{ color: '#000', fontWeight: 'bold' }}>
              Files in {selectedNode?.title}
            </Typography>
            <IconButton 
              onClick={() => setViewFilesModal(false)}
              sx={{ color: '#000' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          
          {currentFiles.length === 0 ? (
            <Typography sx={{ color: '#000' }}>No files found in this folder</Typography>
          ) : (
            <ImageList 
              sx={{ width: '100%' }}
              cols={3} 
              gap={8}
              rowHeight={450}
            >
              {currentFiles.map((file, index) => (
                <LazyImageListItem 
                  key={index}
                  file={file}
                  onClick={() => {
                    if (file.isImage) {
                      window.open(file.url, '_blank');
                    } else {
                      const link = document.createElement('a');
                      link.href = file.url;
                      link.download = file.title;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  }}
                />
              ))}
            </ImageList>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification(null)}
      >
        <Alert 
          severity={notification?.severity || 'info'} 
          onClose={() => setNotification(null)}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DAMFolder; 